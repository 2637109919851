import { Box, Flex, Text, keyframes, Image } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import YesWIcon from "@/assets/yes.svg";

const a1 = keyframes`
    0% {
        width:0%;
     }
    
    100% {
        width: 100%;
     }
`;

const Progress = ({ text, delay }: { text: string; delay?: number }) => {
  const [over, setOver] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setOver(true);
    }, (delay + 1) * 1000);
  }, [delay]);

  return (
    <Box
      sx={{
        height: "40px",
        borderRadius: "15px",
        background: "#D9D9D9",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Flex
        justify={"space-between"}
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          padding: "0 20px 0 25px",
        }}
        align={"center"}
      >
        <Text
          sx={{
            fontSize: "16px",
            color: "#000",
          }}
        >
          {text}
        </Text>
        {over && (
          <Image
            src={YesWIcon}
            sx={{
              width: "22px",
            }}
          ></Image>
        )}
      </Flex>
      <Box
        sx={{
          background: "#fff",
          height: "100%",
          animationDelay: `${delay}s`,
          animationDuration: "1s",
          width: "0%",
          animationFillMode: "forwards",
        }}
        animation={`${a1}`}
      ></Box>
    </Box>
  );
};

const Check = ({ onStepChange }: { onStepChange: () => void }) => {
  const [over, setOver] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setOver(true);
    }, 2000);
  }, []);

  return (
    <Flex
      sx={{
        padding: "4.6296vh 0px 24px",
        height: "100%",
        color: "#fff",
      }}
      flexDir={"column"}
      justify={"space-between"}
    >
      <Box
        sx={{
          padding: "0 30px",
        }}
      >
        <Text
          sx={{
            fontSize: "28px",
            textAlign: "center",
            fontWeight: "900",
          }}
        >
          Checking your account
        </Text>
        <Text
          sx={{
            fontSize: "20px",
            marginTop: "4.7037vh",
          }}
        >
          The more active and older your account is, the more pepes you will
          have.
        </Text>
      </Box>

      <Flex
        sx={{
          gap: "30px",
          padding: "0 30px",
        }}
        flexDir={"column"}
        justify={"space-between"}
      >
        <Progress text="Account Age Verified" delay={1}></Progress>
        <Progress text="Activity Level Analyzed" delay={1}></Progress>
        <Progress text="Telegram Premium Checked" delay={1}></Progress>
        <Progress text="OG Status Confirmed" delay={1}></Progress>
      </Flex>

      <Flex
        onClick={() => {
          onStepChange();
        }}
        sx={{
          width: "326px",
          height: "52px",
          padding: "6px 26px",
          background: "#97E181",
          color: "#000",
          borderRadius: "17px",
          margin: "0 auto",
          fontSize: "20px",
          fontWeight: "bold",
          cursor: "pointer",
          opacity: over ? 1 : 0,
          transition: "opacity 0.5s",
        }}
        align={"center"}
        justify={"center"}
      >
        Continue
      </Flex>
    </Flex>
  );
};

export default Check;
