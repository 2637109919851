import { api } from ".";

export const getChargeInfo = async (address: string = "") => {
  const token = sessionStorage.getItem("jwtToken");
  const response = await api.get(`/Charge/ChargeInfo/${address}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getUserAmountInfo = async (address: string = "") => {
  const token = sessionStorage.getItem("jwtToken");
  const response = await api.get(`/Charge/UserAmountInfo/${address}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const withdraw = async (data: {
  jettonMaster: string;
  toAddress: string;
  amount: number;
}) => {
  const token = sessionStorage.getItem("jwtToken");
  const response = await api.post(`/Charge/Withdraw`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
