import { Box, Flex, Image, Text } from "@chakra-ui/react";
import ArrowIcon from "./assets/arrow.png";
import { useEffect, useMemo, useState } from "react";
import TonIcon from "./assets/tonicon.png";
import { accMul, formatAmount, toFixed } from "@/utils/formatBalance";
import { getHttpV4Endpoint } from "@orbs-network/ton-access";
import { Address, TonClient4 } from "@ton/ton";
import { useTonAddress } from "@tonconnect/ui-react";
import AddIcon from "./assets/add.png";
import SubIcon from "./assets/sub.png";
import { amountsList } from "@/pages/Play";
import moveIcon from "./assets/move.png";
const Amounts = ({
  active,
  onActiveChange,
}: {
  active: number;

  onActiveChange: (index: number) => void;
}) => {
  return (
    <Flex
      gap={"14px"}
      sx={{
        marginTop: "34px",
      }}
    >
      {amountsList.map((item, index) => {
        return (
          <Flex
            align={"center"}
            justify={"center"}
            key={index}
            sx={{
              width: "57px",
              height: "25px",
              background: active === index ? "#97E181" : "#000000",
              borderRadius: "10px",
              color: active === index ? "#000" : "#fff",
            }}
            onClick={() => {
              onActiveChange(index);
            }}
          >
            <Image
              src={TonIcon}
              sx={{
                width: "17px",
                height: "17px",
                marginRight: "2px",
              }}
            ></Image>
            <Text
              sx={{
                fontSize: "15px",
              }}
            >
              {item.label}
            </Text>
          </Flex>
        );
      })}
    </Flex>
  );
};

const network = "testnet";
const SelectBet = ({
  balance,
  tonPrice,
  onCountChange,
  count,
  showTip,
  onShowBet,
  selectAmountIndex,
  onSelectAmount,
}: {
  balance: string;
  tonPrice: string;
  onCountChange: (count: number) => void;
  count: number;
  showTip: boolean;
  selectAmountIndex: number;
  onShowBet: () => void;
  onSelectAmount: (index: number) => void;
}) => {
  const rawAddress = useTonAddress(true);

  const totalEntry = useMemo(() => {
    if (selectAmountIndex >= 0) {
      return toFixed(accMul(amountsList[selectAmountIndex].value, count), 2);
    }

    return "0.00";
  }, [count, selectAmountIndex]);

  const totalEntryUSD = useMemo(() => {
    return toFixed(accMul(totalEntry, tonPrice), 2);
  }, [totalEntry, tonPrice]);

  const balanceUSD = useMemo(() => {
    return toFixed(accMul(balance, tonPrice), 2);
  }, [balance, tonPrice]);

  const handleSubCount = () => {
    if (count > 1) {
      onCountChange(count - 1);
    }
  };

  const handleAddCount = () => {
    onCountChange(count + 1);
  };

  return (
    <Box>
      <Box
        sx={{
          background: "#97E181",
          borderRadius: "25px",
        }}
      >
        <Flex
          sx={{
            height: "48px",
            paddingLeft: "24px",
          }}
          onClick={onShowBet}
          align={"center"}
        >
          <Image
            src={ArrowIcon}
            sx={{
              width: "15px",
              marginRight: "8px",
            }}
          ></Image>
          <Text
            sx={{
              color: "#000",
              fontSize: "15px",
              fontWeight: 500,
            }}
          >
            Current Round
          </Text>
        </Flex>
        <Box
          sx={{
            background: "#232523",
            borderRadius: "25px",
            padding: "20px",
          }}
        >
          <Text
            sx={{
              color: "#A1A1A1",
              fontSize: "13px",
            }}
          >
            TON entry per round
          </Text>
          <Amounts
            active={selectAmountIndex}
            onActiveChange={onSelectAmount}
          ></Amounts>
          <Flex
            sx={{
              marginTop: "20px",
            }}
            align={"center"}
          >
            <Text
              sx={{
                color: "#A1A1A1",
                fontSize: "15px",
              }}
            >
              TON in wallet:
            </Text>
            <Image
              src={TonIcon}
              sx={{
                width: "17px",
                height: "17px",
                marginLeft: "6px",
                marginRight: "4px",
              }}
            ></Image>
            <Text
              sx={{
                marginRight: "9px",
                fontSize: "15px",
              }}
            >
              Ton {toFixed(balance, 2)}
            </Text>
            <Text
              sx={{
                color: "#A1A1A1",
                fontSize: "13px",
                fontWeight: 500,
              }}
            >
              ${balanceUSD}
            </Text>
          </Flex>
          <Text
            sx={{
              marginTop: "16px",
              fontSize: "13px",
            }}
          >
            Number of Rounds
          </Text>
          <Flex
            sx={{
              marginTop: "12px",
            }}
            justify={"space-between"}
          >
            <Flex
              align={"center"}
              justify={"space-between"}
              sx={{
                borderRadius: "10px",
                border: "1px solid #97E181",
                background: "#4C4C4C",
                width: "100%",
                height: "53px",
                fontSize: "15px",
                padding: "0 6px",
              }}
            >
              <Flex
                onClick={() => {
                  handleSubCount();
                }}
                align={"center"}
                justify={"center"}
                sx={{
                  width: "65px",
                  background: "#7C7C7C",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                <Image
                  src={SubIcon}
                  sx={{
                    width: "38px",
                    height: "38px",
                  }}
                ></Image>
              </Flex>

              <Text>{count}</Text>

              <Flex
                onClick={() => {
                  handleAddCount();
                }}
                align={"center"}
                justify={"center"}
                sx={{
                  width: "65px",
                  background: "#7C7C7C",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                <Image
                  src={AddIcon}
                  sx={{
                    width: "38px",
                    height: "38px",
                  }}
                ></Image>
              </Flex>
            </Flex>
            {/* <Flex
              sx={{
                borderRadius: "10px",
                border: "1px solid #97E181",
                background: "#4C4C4C",
                width: "85px",
                height: "53px",
                color: "#fff",
                fontSize: "15px",
              }}
              align={"center"}
              justify={"center"}
            >
              MAX
            </Flex> */}
          </Flex>
        </Box>
      </Box>
      <Flex
        justify={"space-between"}
        sx={{
          marginTop: "16px",
        }}
      >
        <Text
          sx={{
            fontSize: "13px",
            color: "#A1A1A1",
          }}
        >
          Total Entry
        </Text>
        <Flex sx={{}} align={"center"}>
          <Image
            src={TonIcon}
            sx={{
              width: "17px",
              height: "17px",
            }}
          ></Image>
          <Text
            sx={{
              fontSize: "15px",
              margin: "0 8px 0 4px",
            }}
          >
            Ton {totalEntry}
          </Text>
          <Text
            sx={{
              color: "#A1A1A1",
              fontFamily: "Avenir",
              fontSize: "13px",
            }}
          >
            ${totalEntryUSD}
          </Text>
        </Flex>
      </Flex>
      {/* {count > 1 && (
        <Flex
          justify={"space-between"}
          sx={{
            marginTop: "20px",
          }}
        >
          <Text
            sx={{
              fontSize: "13px",
              color: "#A1A1A1",
            }}
          >
            Est.gas savings:
          </Text>
          <Flex sx={{}} align={"center"}>
            <Image
              src={TonIcon}
              sx={{
                width: "17px",
                height: "17px",
              }}
            ></Image>
            <Text
              sx={{
                fontSize: "15px",
                margin: "0 8px 0 4px",
              }}
            >
              Ton {accMul(0.004, count - 1)}
            </Text>
            <Text
              sx={{
                color: "#A1A1A1",
                fontFamily: "Avenir",
                fontSize: "13px",
              }}
            >
              $334
            </Text>
          </Flex>
        </Flex>
      )} */}
      {
        <Flex
          align={"center"}
          sx={{
            marginTop: "20px",
            borderRadius: "10px",
            background: "#0E0E0E",
          }}
        >
          <Image
            src={moveIcon}
            sx={{
              width: "24px",
              marginRight: "10px",
            }}
          ></Image>
          <Text
            sx={{
              color: "#EBAC00",
              fontFamily: "Lexend",
              fontSize: "12px",
            }}
          >
            ⭐ When the current round of play is almost over, the bet may be
            transferred to the next round{" "}
          </Text>
        </Flex>
      }
    </Box>
  );
};

export default SelectBet;
