import { Box, Image, Flex, Text } from "@chakra-ui/react";
import GameIcon from "./assets/game.png";
import { useNavigate } from "react-router-dom";
import NewIcon from "@/components/Home/assets/NEW.png";

const RingInfo = () => {
  const navigate = useNavigate();
  return (
    <Flex
      sx={{
        background: "#232523",
        borderRadius: "15px",
        padding: "12px 4px 12px 8px ",
        marginTop: "20px",
        color: "white",
        width: "100%",
        position: "relative",
      }}
      onClick={() => {
        navigate("/play");
      }}
    >
      <Image
        src={NewIcon}
        sx={{
          position: "absolute",

          width: "54px",
          right: "0px",
          top: "0px",
          transform: "translate(0%,-50%)",
        }}
      ></Image>
      <Image
        src={GameIcon}
        sx={{
          width: "64px",
          marginRight: "16px",
        }}
      ></Image>
      <Box sx={{}}>
        <Text
          sx={{
            fontSize: "15px",
            fontWeight: 900,
            marginTop: "4px",
          }}
        >
          🎡PEPES Spin
        </Text>
        <Text
          sx={{
            fontSize: "10px",
            fontWeight: 500,
            marginTop: "12px",
          }}
        >
          Go big or go home in PEPES Spin — the heart-pounding, winner-takes-all
          battle royale!
        </Text>
      </Box>
    </Flex>
  );
};

export default RingInfo;
