import { Box } from "@chakra-ui/react";
import React from "react";
import TaskWrap from "./TaskWrap";
import { partner } from "./taskList";

const Partners = ({
  currentTask,
  currentStatus,
  completeList,
  handleDoTask,
}: {
  currentTask: number; //当前任务
  currentStatus: number; //当前任务状态
  completeList: any; //所有已完成任务列表
  handleDoTask: (scoreType: number) => Promise<void>;
}) => {
  return (
    <Box>
      {partner.map((item, index) => {
        return (
          <TaskWrap
            key={index}
            currentTask={currentTask}
            currentStatus={currentStatus}
            completeList={completeList}
            handleDoTask={handleDoTask}
            info={item}
          ></TaskWrap>
        );
      })}
    </Box>
  );
};

export default Partners;
