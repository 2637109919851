import { Box, Image } from "@chakra-ui/react";
import LogoIcon from "@/assets/b-logo.png";
import React, { createContext, useContext, useEffect, useState } from "react";
import {
  getInvite,
  getLeaderboard,
  getSuccessTask,
  getDailyReward,
  getConnectWalletCount,
} from "@/api";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const UserDataContext = createContext<{
  init: boolean;
  userData: any;
  onUserDataChange: (data: any) => void;
  onFirstChange: () => void;
  handleGetFriend: () => void;
  leaderboardMyData: any;
  leaderboardList: any;
  friendList: any;
  completeList: any;
  friendTotal: number;
  dailyReward: any;
  leaderboardTotal: number;
  connectWalletCount: number;
  handleGetLeaderboard: () => void;
  handleGetSuccessTask: () => void;
  handleGetDailyReward: () => void;
  handleGetConnectWalletCount: () => void;
}>(null);

export const dailyStartTime = dayjs(1730764800000).utc();
export const dailyEndTime = dailyStartTime.add(7, "day");

export const UserDataProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const leaderboardRef = React.useRef(0);
  const friendRef = React.useRef(0);
  const completeRef = React.useRef(0);
  const [init, setInit] = React.useState(false); // [1
  const [userData, setUserData] = React.useState({});

  const [leaderboardMyData, setLeaderboardMyData] = React.useState({
    userId: 0,
    firstName: "",
    lastName: "",
    score: 0,
    rank: "",
  });
  const [dailyReward, setDailyReward] = React.useState([]);
  const [leaderboardTotal, setLeaderboardTotal] = useState(0);
  const [leaderboardList, setLeaderboardList] = React.useState([]);
  const [friendList, setFriendList] = React.useState([]);
  const [friendTotal, setFriendTotal] = useState(0);
  const [completeList, setCompleteList] = React.useState([]);
  const [connectWalletCount, setConnectWalletCount] = useState(0);
  const onUserDataChange = (data: any) => {
    setUserData(data);
  };

  const handleGetConnectWalletCount = async () => {
    try {
      const res = await getConnectWalletCount();
      if (res.code === 200) {
        console.log(res.data, "res.data");
        setConnectWalletCount(res.data);
      }
    } catch (error) {
      // console.error("获取连接钱包次数失败:", error);
    }
  };

  const handleGetLeaderboard = async () => {
    const now = new Date().getTime();
    if (now - leaderboardRef.current < 1000) {
      return;
    }
    leaderboardRef.current = now;
    const res = await getLeaderboard();
    if (res.code === 200) {
      setLeaderboardTotal(30000 + 3 * res.data.dataCount);
      let newRankList = [];
      let otherData = res.data.data.slice(1, res.data.data.length);
      let myData = res.data.data[0];
      newRankList = otherData;
      setLeaderboardMyData(myData);
      setLeaderboardList(newRankList);
    }
  };

  const handleGetDailyReward = async () => {
    const formatDate = (date: Date) => {
      const year = date.getFullYear().toString().slice(-2);
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}${month}${day}`;
    };

    const today = new Date();
    const startDate = dailyStartTime.toDate();
    const endDate = dailyEndTime.toDate();
    const dates = [];

    // 如果今天超过了结束时间，使用结束时间作为基准日期
    const baseDate = today > endDate ? endDate : today;

    // 计算从基准日期开始往前最多3天的日期，但不能早于开始时间
    for (let i = 0; i < 3; i++) {
      const date = new Date(baseDate);
      date.setDate(baseDate.getDate() - i);
      if (date >= startDate && date <= endDate) {
        dates.push(formatDate(date));
      }
    }

    try {
      // 只请求有效日期范围内的数据
      const results = await Promise.all(
        dates.map((date) => getDailyReward(date))
      );

      const newDailyReward = results.map((result) => {
        return result.data;
      });

      // console.log(newDailyReward, "results");
      setDailyReward(newDailyReward);
      return results;
    } catch (error) {
      console.error("获取每日奖励失败:", error);
    }
  };

  const handleGetFriend = async () => {
    const now = new Date().getTime();
    if (now - friendRef.current < 1000) {
      return;
    }
    friendRef.current = now;
    const res = await getInvite();
    if (res.code === 200) {
      setFriendTotal(res.data.dataCount);
      setFriendList(res.data.data);
    }
  };

  const handleGetSuccessTask = async () => {
    const now = new Date().getTime();
    if (now - completeRef.current < 1000) {
      return;
    }
    completeRef.current = now;
    const res = await getSuccessTask();
    if (res.code === 200) {
      const data = res.data;
      setCompleteList(data);
    }
  };

  const onFirstChange = () => {
    handleGetLeaderboard();
    handleGetDailyReward();
    handleGetFriend();
    handleGetSuccessTask();
    handleGetConnectWalletCount();
    setTimeout(() => {
      setInit(true);
    }, 1500);
  };

  return (
    <UserDataContext.Provider
      value={{
        init,
        userData,
        onUserDataChange,
        onFirstChange,
        handleGetFriend,
        leaderboardMyData,
        friendList,
        friendTotal,
        leaderboardList,
        completeList,
        leaderboardTotal,
        dailyReward,
        handleGetLeaderboard,
        handleGetSuccessTask,
        handleGetDailyReward,
        connectWalletCount,
        handleGetConnectWalletCount,
      }}
    >
      <Box
        sx={{
          background: "#000",
          height: "100%",
          width: "100%",
          position: "fixed",
          top: 0,
          left: 0,
          transition: "all 0.3s",
          opacity: init ? 0 : 1,
          zIndex: init ? -1 : 100,
        }}
      >
        <Box
          sx={{
            width: "195px",
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <Image src={LogoIcon} sx={{}}></Image>
        </Box>
      </Box>
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserData = () => {
  return useContext(UserDataContext);
};
