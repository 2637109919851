import {
  Box,
  Text,
  Image,
  Flex,
  Input,
  SliderMark,
  SliderTrack,
  SliderFilledTrack,
  Slider,
  SliderThumb,
} from "@chakra-ui/react";
import { GreenButton } from "../Button";
import { useEffect, useState } from "react";
import { getHttpV4Endpoint } from "@orbs-network/ton-access";
import TonIcon from "./assets/ton-icon.png";
import {
  Address,
  JettonMaster,
  TonClient4,
  JettonWallet,
  toNano,
  beginCell,
} from "@ton/ton";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import { getChargeInfo, getUserAmountInfo, withdraw } from "@/api/earn";
import {
  accDiv,
  accMul,
  formatAmount,
  parseAmount,
} from "@/utils/formatBalance";
import { useNotifications } from "reapop";
import config from "@/constants/tgConfig";

const sliderList = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 25,
    label: "25%",
  },
  {
    value: 50,
    label: "50%",
  },
  {
    value: 75,
    label: "75%",
  },
  {
    value: 100,
    label: "MAX",
  },
];

const Detail = ({
  pool,
  onShowDetail,
}: {
  pool: any;
  onShowDetail: () => void;
}) => {
  const rawAddress = useTonAddress(false);
  const [tonConnectUI] = useTonConnectUI();
  const [inputValue, setInputValue] = useState("");
  const friendeAddress = useTonAddress(true);
  const [sliderValue, setSliderValue] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [depositAmount, setDepositAmount] = useState("0");
  const [rewardAmount, setRewardAmount] = useState("0");
  const [balance, setBalance] = useState("0");
  const [TON_CLIENT, setTON_CLIENT] = useState<any>(null);
  const { notify } = useNotifications();

  const handleBalance = async () => {
    try {
      if (pool.isNative) {
        const lastBlock = await TON_CLIENT.getLastBlock();
        const seqno = lastBlock.last.seqno;
        const accountInfo = await TON_CLIENT.getAccount(
          seqno,
          Address.parse(rawAddress)
        );
        const balance = accountInfo.account.balance.coins;
        setBalance(formatAmount(balance.toString(), pool.decimals));
      } else {
        const jettonMasterAddress = pool.jettonMaster;
        const userAddress = Address.parse(rawAddress);
        const jettonMaster = TON_CLIENT.open(
          JettonMaster.create(Address.parse(jettonMasterAddress))
        );
        const jettonWalletAddress = await jettonMaster.getWalletAddress(
          userAddress
        );
        const jettonWallet = TON_CLIENT.open(
          JettonWallet.create(jettonWalletAddress)
        );
        const balance = await jettonWallet.getBalance();
        setBalance(formatAmount(balance.toString(), pool.decimals));
      }
    } catch (e) {
      console.log("get balance error", e);
    }
  };

  const handleActiveIndex = (index: number) => {
    setActiveIndex(index);
    setSliderValue(0);
    setInputValue("0");
  };

  const handleTransfer = () => {
    if (!pool || !TON_CLIENT || !rawAddress) {
      return;
    }
    if (pool.isNative) {
      handleSendTon(inputValue);
    } else {
      handleTransferJetton(inputValue);
    }
  };

  const handleWithdraw = async () => {
    if (!pool || !TON_CLIENT || !rawAddress) {
      return;
    }

    try {
      const res = await withdraw({
        jettonMaster: pool.jettonMaster,
        toAddress: rawAddress,
        amount: Number(inputValue),
      });
      if (res.code === 200) {
        notify({
          message: "Stake Success",
          status: "success",
        });
        return;
      }

      notify({
        message: res.message,
        status: "error",
      });
    } catch (e) {
      notify({
        message: e + "",
        status: "error",
      });
    }
  };

  const handleSendTon = async (amount: string) => {
    try {
      const tonData = await getChargeInfo();
      if (tonData.code == 200) {
        const comment = tonData.data.comment;
        const transaction = {
          validUntil: Math.floor(Date.now() / 1000) + 60 * 60 * 24 * 7, // 7 days
          messages: [
            {
              address: tonData.data.inAddress, // destination address
              amount: toNano(amount).toString(), //Toncoin in nanotons
              payload: beginCell()
                .storeUint(0, 32)
                .storeStringTail(comment)
                .endCell()
                .toBoc()
                .toString("base64"),
            },
          ],
        };
        const res = await tonConnectUI.sendTransaction(transaction, {
          notifications: [],
        });
        notify({
          message: "Stake Success",
          status: "success",
        });
        setInputValue("");
      }
    } catch (e) {
      console.log(e);
      notify({
        message: "Transaction Canceled",
        status: "error",
      });
    }
  };

  const handleTransferJetton = async (amount: string) => {
    try {
      const tonData = await getChargeInfo(pool.jettonMaster);
      console.log(tonData, "tonData");
      if (tonData.code == 200) {
        const jettonMasterAddress = pool.jettonMaster;
        const destinationAddress = Address.parse(tonData.data.inAddress);
        const userAddress = Address.parse(rawAddress);
        const jettonMaster = TON_CLIENT.open(
          JettonMaster.create(Address.parse(jettonMasterAddress))
        );
        const jettonWallet = await jettonMaster.getWalletAddress(userAddress);

        const comment = tonData.data.comment;
        // 创建包含评论的 forward payload
        const commentCell = beginCell()
          .storeUint(0, 32) // 预留32位用于标识
          .storeStringTail(comment) // 存储评论内容
          .endCell();

        const body = beginCell()
          .storeUint(0xf8a7ea5, 32)
          .storeUint(0, 64) // query id
          .storeCoins(BigInt(parseAmount(amount, pool.decimals))) // amount
          .storeAddress(destinationAddress) // des address
          .storeAddress(userAddress) // response address
          .storeMaybeRef(null) // custom_payload
          .storeCoins(1) // forward ton amount
          .storeMaybeRef(commentCell) // forward payload with comment
          .endCell();
        const transaction = {
          validUntil: Math.floor(Date.now() / 1000) + 60 * 60 * 24 * 7, // 7 days
          messages: [
            {
              address: jettonWallet.toString(),
              amount: toNano("0.3").toString(), // for gas fees, excess will be returned
              payload: body.toBoc().toString("base64"),
            },
          ],
        };

        await tonConnectUI.sendTransaction(transaction, {
          notifications: [],
        });

        notify({
          message: "Stake Success",
          status: "success",
        });
        setInputValue("");
      }
    } catch (e) {
      notify({
        message: "Transaction Canceled",
        status: "error",
      });
    }
  };

  const handleGetUserAmountInfo = async () => {
    const res = await getUserAmountInfo(pool.jettonMaster);
    if (res.code == 200) {
      setDepositAmount(res.data.amount);
      setRewardAmount(res.data.score);
    }
  };

  const handleInitEndpoint = async () => {
    const endpoint = await getHttpV4Endpoint({
      network: config.network,
    });
    const TON_CLIENT = new TonClient4({ endpoint });
    setTON_CLIENT(TON_CLIENT);
  };

  useEffect(() => {
    if (!pool || !TON_CLIENT || !rawAddress) {
      return;
    }

    handleBalance();
    handleGetUserAmountInfo();

    const timer = setInterval(() => {
      handleBalance();
      handleGetUserAmountInfo();
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  }, [TON_CLIENT, pool, rawAddress]);

  useEffect(() => {
    handleInitEndpoint();
  }, []);

  return (
    <Box
      sx={{
        padding: "0 20px",
        color: "#fff",
      }}
    >
      <Flex
        sx={{
          marginTop: "20px",
        }}
        align={"center"}
      >
        <Image
          src={pool.img}
          sx={{
            width: "28px",
            marginRight: "10px",
          }}
        ></Image>
        <Text
          sx={{
            fontSize: "25px",
            fontStyle: "normal",
            fontWeight: 700,
          }}
        >
          {pool.name} POOL
        </Text>
      </Flex>
      <Box
        sx={{
          marginTop: "20px",
        }}
      >
        <Flex
          flexDir={"column"}
          align={"center"}
          sx={{
            padding: "10px",
            background: "#232523",
            borderRadius: "15px",
            gap: "5px",
          }}
        >
          <Text
            sx={{
              color: "#818181",
              fontSize: "15px",
            }}
          >
            Reward:
          </Text>
          <Flex align={"center"}>
            <Image
              src={TonIcon}
              sx={{
                width: "29px",
                marginRight: "4px",
              }}
            ></Image>
            <Text
              sx={{
                fontSize: "25px",
              }}
            >
              {rewardAmount}
            </Text>
          </Flex>
        </Flex>
        <Flex
          flexDir={"column"}
          align={"center"}
          sx={{
            padding: "10px",
            background: "#232523",
            borderRadius: "15px",
            gap: "5px",
            marginTop: "10px",
          }}
        >
          <Text
            sx={{
              color: "#818181",
              fontSize: "15px",
            }}
          >
            ${pool.name} Staked:
          </Text>
          <Flex align={"center"}>
            <Image
              src={pool.img}
              sx={{
                width: "29px",
                marginRight: "4px",
              }}
            ></Image>
            <Text
              sx={{
                fontSize: "25px",
              }}
            >
              {depositAmount}
            </Text>
          </Flex>
        </Flex>
        <Flex
          justify={"space-between"}
          sx={{
            width: "100%",
            marginTop: "20px",
          }}
        >
          <GreenButton
            sx={{
              width: "155px !important",
              height: "36px !important",
              background: activeIndex != 0 && "#232523 !important",
              color: activeIndex === 0 ? "#232523" : "#A1A1A1 !important",
              border:
                activeIndex === 0
                  ? "1px solid transparent"
                  : "1px solid #4C4C4C",
              borderRadius: "17px !important",
            }}
            onClick={() => {
              handleActiveIndex(0);
            }}
          >
            Stake
          </GreenButton>
          <GreenButton
            sx={{
              width: "155px !important",
              height: "36px !important",
              background: activeIndex != 1 && "#232523 !important",
              color: activeIndex === 1 ? "#232523" : "#A1A1A1 !important",
              border:
                activeIndex === 1
                  ? "1px solid transparent"
                  : "1px solid #4C4C4C",
              borderRadius: "17px !important",
            }}
            onClick={() => {
              handleActiveIndex(1);
            }}
          >
            Withdraw
          </GreenButton>
        </Flex>
        <Box
          sx={{
            padding: "16px",
            background: "#232523",
            marginTop: "20px",
            borderRadius: "15px",
          }}
        >
          <Input
            variant={"unstyled"}
            value={inputValue}
            onChange={(e) => {
              if (e.target.value === "") {
                setInputValue("");
                setSliderValue(0);
                return;
              }
              const amount = activeIndex === 0 ? balance : depositAmount;
              const fAmount = amount;
              let rAmount = e.target.value;
              if (parseFloat(e.target.value) > Number(fAmount)) {
                rAmount = fAmount;
              }

              const rate = Math.floor(
                Number(accMul(accDiv(parseFloat(rAmount), fAmount), 100))
              );

              setInputValue(rAmount);
              setSliderValue(rate);
            }}
            sx={{
              width: "100%",
              height: "38px",
              borderRadius: "19px",
              border: "2px solid #4C4C4C",
              background: "#4C4C4C",
              margin: "0 auto",
              color: "#fff",
              fontWeight: 700,
              paddingLeft: "12px",
              "&::placeholder": {
                color: "#fff",
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: 700,
              },
            }}
            placeholder={"0.00"}
          ></Input>
          {activeIndex === 0 ? (
            <Text
              sx={{
                fontSize: "15px",
                fontWeight: 700,
                marginTop: "9px",
                color: "#7C7C7C",
              }}
            >
              Balance: {balance} ${pool.name}
            </Text>
          ) : (
            <Text
              sx={{
                fontSize: "15px",
                fontWeight: 700,
                marginTop: "9px",
                color: "#7C7C7C",
              }}
            >
              Available: {depositAmount} ${pool.name}
            </Text>
          )}
          <Flex
            align={"center"}
            justify={"space-between"}
            sx={{
              marginTop: "18px",
            }}
          >
            {sliderList.map((item, index) => {
              return (
                <Flex
                  sx={{
                    width: "47px",
                    height: "24px",
                    padding: "2px 9px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    flexShrink: 0,
                    borderRadius: "7px",
                    background: "#000",
                  }}
                >
                  <Text
                    key={index}
                    sx={{
                      fontSize: "15px",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      const amount =
                        activeIndex === 0 ? balance : depositAmount;
                      setInputValue(accMul(amount, item.value / 100));
                      setSliderValue(item.value);
                    }}
                  >
                    {item.label}
                  </Text>
                </Flex>
              );
            })}
          </Flex>
          <Flex>
            <Slider
              id="slider"
              focusThumbOnChange={false}
              value={sliderValue}
              min={0}
              max={100}
              onChange={(v) => {
                const rate = Number(v) / 100;

                const amount = activeIndex === 0 ? balance : depositAmount;
                if (pool.isNative) {
                  setInputValue(accMul(amount, rate));
                } else {
                  setInputValue(accMul(amount, rate));
                }
                setSliderValue(v);
              }}
              sx={{
                marginTop: "20px",
              }}
            >
              <SliderTrack
                sx={{
                  background: "#DDD",
                  height: "13px",
                  borderRadius: "6px",
                }}
              >
                <SliderFilledTrack
                  sx={{
                    background: "#97E181",
                  }}
                />
              </SliderTrack>
              <SliderMark
                value={sliderValue}
                textAlign="center"
                sx={{
                  background: "transparent",
                  color: "#fff",
                  fontSize: "15px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  textAlign: "center",
                  width: "40px",
                }}
                mt="3"
                ml="-4"
              >
                {sliderValue}%
              </SliderMark>
              <SliderThumb
                sx={{
                  background: "linear-gradient(90deg, #00F6FF 0%, #BF0 100%)",
                  border: "2px solid #000",
                  width: "22px",
                  height: "22px",
                }}
              />
            </Slider>
          </Flex>
          <GreenButton
            onClick={() => {
              if (activeIndex === 0) {
                handleTransfer();
              } else {
                handleWithdraw();
              }
            }}
            sx={{
              height: "47px !important",
              marginTop: "30px",
              color: "#fff",
              fontWeight: 900,
            }}
          >
            {activeIndex === 0 ? "Stake" : "Withdraw"}
          </GreenButton>
        </Box>
      </Box>
    </Box>
  );
};
export default Detail;
