import { Box, Flex, Switch, Text, Image } from "@chakra-ui/react";
import TonIcon from "./assets/tonicon.png";

const ClaimWin = ({
  onlyYour,
  onOnlyYourChange,
}: {
  onlyYour: boolean;
  onOnlyYourChange: () => void;
}) => {
  return (
    <Box
      sx={{
        marginTop: "20px",
      }}
    >
      <Flex align={"center"}>
        <Switch
          isChecked={onlyYour}
          sx={{
            border: `2px solid ${onlyYour ? "#97E181" : "#4C4C4C"} `,
            borderRadius: "16px",
            "&": {
              ".chakra-switch__track": {
                background: "#232523",
              },
            },
          }}
          colorScheme={"#fff"}
          onChange={onOnlyYourChange}
        />
        <Text
          sx={{
            color: "#FFF",
            fontSize: "15px",
            fontWeight: 500,
            marginLeft: "13px",
          }}
        >
          Only Your Rounds
        </Text>
      </Flex>
      {/* <Box
        sx={{
          border: "1px solid #97E181",
          marginTop: "16px",
          padding: "20px 12px",
          borderRadius: "10px",
        }}
      >
        <Flex>
          <Text
            sx={{
              fontSize: "13px",
            }}
          >
            Your Unclaimed Winnings
          </Text>
          <Image
            src={TonIcon}
            sx={{
              width: "17px",
              marginLeft: "auto",
              marginRight: "4px",
            }}
          ></Image>
          <Text
            sx={{
              fontSize: "15px",
            }}
          >
            Ton {0.03}
          </Text>
        </Flex>
        <Flex
          sx={{
            height: "37px",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "10px",
            background: "#323232",
            marginTop: "20px",
          }}
          align={"center"}
          justify={"center"}
        >
          Claim Now
        </Flex>
      </Box> */}
    </Box>
  );
};
export default ClaimWin;
