import { Box, Flex, Text, Image } from "@chakra-ui/react";
import { LuckyWheel } from "@lucky-canvas/react";
import { useEffect, useMemo, useRef, useState } from "react";
import ClockIcon from "./assets/clock.png";
import { ReactComponent as LeftIcon } from "./assets/left.svg";
import { ReactComponent as RightIcon } from "./assets/right.svg";
import { ReactComponent as RRightIcon } from "./assets/rright.svg";
import DArrowIcon from "./assets/d-arrow.png";
import { GameInfo } from "@/pages/Play";
import { accAdd, accDiv, accMul } from "@/utils/formatBalance";
import roundColors from "./roundColors";
import TonIcon from "./assets/tonicon.png";
import WinnerAva from "./assets/winerAva.png";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import { avatarImg } from "@/utils/avatars";

const RIcon = ({
  onClick,
  children,
}: {
  onClick: () => void;
  children: React.ReactNode;
}) => {
  return (
    <Flex
      onClick={onClick}
      sx={{
        width: "31px",
        height: "28px",
        border: "1px solid #4C4C4C",
        borderRadius: "10px",
      }}
      align={"center"}
      justify={"center"}
    >
      {children}
    </Flex>
  );
};

const RoundInfo = ({
  onShowRoundHistory,
  onToRound,
  lastRound,
  zhuan,
  onZhuan,
  gameInfo,
  onShowRoundResult,
  showRoundResult,
}: {
  onShowRoundHistory: () => void;
  onToRound: (id: number, zhuan?: boolean) => void;
  lastRound: number;
  zhuan: {
    [roundId: number]: boolean;
  };
  onZhuan: (id: number, status: boolean) => void;
  gameInfo: GameInfo;
  onShowRoundResult: (flag: boolean) => void;
  showRoundResult: boolean;
}) => {
  const [shuaxin, setShuaxin] = useState(false);
  const toLastRoundRef = useRef(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const { width, height } = useWindowSize();
  const isPlaying = useRef(false);
  const [blocks] = useState([{ padding: "8px", background: "transparent" }]);
  const myLucky = useRef<any>();

  const timer = useRef<any>();
  const stopTimer = useRef<any>();

  const [realPrizes, _detailPrizes, allBet, winerInfo] = useMemo(() => {
    if (gameInfo.betHistorys.length === 0) {
      return [
        [
          {
            background: "#555",
            range: 100,
            mid: 50,
          },
        ],
        [
          {
            background: "#555",
          },
        ],
        "0",
        {},
      ];
    }

    const allBet = gameInfo.betHistorys.reduce((acc, item) => {
      return accAdd(acc, item.betAmount);
    }, "0");

    let count = 0;
    const _realPrizes = gameInfo.betHistorys.map((item, index) => {
      const rate = accMul(accDiv(item.betAmount, allBet), 100);
      const range = Math.floor(Number(rate));
      const min = count;
      const max = count + range;
      count = max;
      const random = Math.floor(Math.random() * (max - min + 1)) + min;
      return {
        background: roundColors[index],
        range: range,
        mid: random,
      };
    });

    const _detailPrizes = [];
    for (let i = 0; i < _realPrizes.length; i++) {
      for (let j = 0; j < _realPrizes[i].range; j++) {
        _detailPrizes.push({ background: _realPrizes[i].background });
      }
    }

    const winer = gameInfo.betHistorys.find((item) => {
      return item.userId == gameInfo.winnerUser;
    });

    return [_realPrizes, _detailPrizes, allBet, winer];
  }, [gameInfo]);

  useEffect(() => {
    if (gameInfo.status == 3 || gameInfo.status == 4) {
      const winer = gameInfo.betHistorys.findIndex((item) => {
        return item.userId == gameInfo.winnerUser;
      });

      if (winer == -1) {
        toLastRoundRef.current = true;
        return;
      }

      if (zhuan[gameInfo.id]) {
        if (isPlaying.current) {
          return;
        }
        if (gameInfo.betHistorys.length > 1) {
          isPlaying.current = true;
          setShuaxin(!shuaxin); //只是为了刷新界面
          myLucky.current.play();

          stopTimer.current = setTimeout(() => {
            myLucky.current?.stop(realPrizes[winer].mid);
          }, 8000);
        }
      } else {
        onShowRoundResult(true);
      }
    } else {
      onShowRoundResult(false);
    }
  }, [gameInfo.id, gameInfo.status, gameInfo.betHistorys, zhuan, realPrizes]);

  useEffect(() => {
    const t = setInterval(() => {
      if (lastRound == gameInfo.id + 1) {
        if (toLastRoundRef.current) {
          toLastRoundRef.current = false;
          onToRound(lastRound, true);
        }
      }
    }, 3000);

    return () => {
      clearInterval(t);
    };
  }, [gameInfo.id, lastRound]);

  useEffect(() => {
    return () => {
      timer.current && clearTimeout(timer.current);
      stopTimer.current && clearTimeout(stopTimer.current);
    };
  }, []);

  return (
    <Box
      sx={{
        height: "360px",
        flexShrink: 0,
        borderRadius: "25px",
        background: "#232523",
        padding: "20px",
      }}
    >
      {showConfetti && (
        <Confetti
          width={width}
          height={height}
          recycle={false}
          onConfettiComplete={() => {
            setShowConfetti(false);
          }}
        />
      )}
      <Flex sx={{}} align={"center"} justify={"space-between"}>
        <Text>Round #{gameInfo.id}</Text>
        <Flex
          sx={{
            "& img": {
              width: "31px",
            },
          }}
          align={"center"}
          gap={"10px"}
        >
          <RIcon onClick={onShowRoundHistory}>
            <Image src={ClockIcon} sx={{}}></Image>
          </RIcon>
          <RIcon
            onClick={() => {
              if (isPlaying.current) {
                return;
              }

              toLastRoundRef.current = false;
              timer.current && clearTimeout(timer.current);
              gameInfo.id > 1 && onToRound(gameInfo.id - 1);
            }}
          >
            <LeftIcon
              style={{
                color: gameInfo.id > 1 ? "#fff" : "#4C4C4C",
                width: "8px",
              }}
            ></LeftIcon>
          </RIcon>
          <RIcon
            onClick={() => {
              if (isPlaying.current) {
                return;
              }

              toLastRoundRef.current = false;
              timer.current && clearTimeout(timer.current);

              if (lastRound > gameInfo.id) {
                if (lastRound == gameInfo.id + 1) {
                  onToRound(gameInfo.id + 1, true);
                } else {
                  onToRound(gameInfo.id + 1);
                }
              }
            }}
          >
            <RightIcon
              style={{
                color: lastRound > gameInfo.id ? "#fff" : "#4C4C4C",
                width: "8px",
              }}
            ></RightIcon>
          </RIcon>
          <RIcon
            onClick={() => {
              if (isPlaying.current) {
                return;
              }
              toLastRoundRef.current = false;
              timer.current && clearTimeout(timer.current);
              lastRound > gameInfo.id && onToRound(lastRound, true);
            }}
          >
            <RRightIcon
              style={{
                color: lastRound > gameInfo.id ? "#fff" : "#4C4C4C",
                width: "13px",
              }}
            ></RRightIcon>
          </RIcon>
        </Flex>
      </Flex>
      <Text
        sx={{
          height: "30px",
          lineHeight: "30px",
          opacity: isPlaying.current ? 1 : 0,
          color: "  #97E181 ",
          fontSize: "15px",
          fontWeight: 900,
        }}
      >
        Generating Randomness...
      </Text>
      <Box
        sx={{
          height: "260px",
          width: "260px",
          position: "relative",
          margin: "0 auto 0",
          borderRadius: "50%",
          border: "6px solid #D9D9D9",
        }}
      >
        <LuckyWheel
          ref={myLucky}
          width="248px"
          height="248px"
          blocks={blocks}
          prizes={_detailPrizes}
          onEnd={(prize: any) => {
            onShowRoundResult(true);
            onZhuan(gameInfo.id, false);
            setShowConfetti(true);
            isPlaying.current = false;

            timer.current = setTimeout(() => {
              toLastRoundRef.current = true;
            }, 10000);
          }}
          defaultConfig={{
            decelerationTime: 3000,
            gutter: 0,
          }}
        />
        <Image
          src={DArrowIcon}
          sx={{
            width: "26px",
            position: "absolute",
            top: "-6px",
            left: "50%",
            transform: "translate(-50%, 0%)",
            zIndex: 1,
          }}
        ></Image>
        <Flex
          sx={{
            background: "#232523",
            width: "150px",
            height: "150px",
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "50%",
          }}
          align={"center"}
          justify={"center"}
        >
          {
            <Flex
              sx={{}}
              flexDir={"column"}
              align={"center"}
              justify={"center"}
            >
              <Box
                sx={{
                  width: "62px",
                  background: ` url(${avatarImg(
                    winerInfo?.userId
                  )}), url(${WinnerAva})`,
                  height: showRoundResult ? "56px" : "0",
                  transition: "all 0.5s",
                  backgroundSize: "36px 36px,100% 100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center 11px,center",
                }}
              ></Box>

              <Text
                sx={{
                  fontSize: "15px",
                  fontWeight: 900,
                  marginTop: "4px",
                  transition: "all 0.5s",
                  height: showRoundResult ? "18px" : "0",
                  overflow: "hidden",
                  background: "linear-gradient(0deg, #FFD900 0%, #F09F0C 100%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                {winerInfo?.firstName ?? ""} {winerInfo?.lastName ?? ""}
              </Text>
              <Flex
                align={"center"}
                sx={{
                  marginTop: "5px",
                }}
              >
                <Image
                  src={TonIcon}
                  sx={{ width: "25px", height: "25px", marginRight: "5px" }}
                ></Image>
                <Text
                  sx={{
                    fontSize: showRoundResult ? "30px" : "35px",
                    fontWeight: "bold",
                  }}
                >
                  {allBet}
                </Text>
              </Flex>
            </Flex>
          }
        </Flex>
      </Box>
    </Box>
  );
};

export default RoundInfo;
