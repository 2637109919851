import { Box, Flex, Text, Image } from "@chakra-ui/react";
import LogoIcon from "@/assets/logo.svg";
import ConIcon from "@/assets/con.svg";
import { useUserData } from "@/contexts/SubmitRequest";
const Congrats = ({ onStepChange }: { onStepChange: () => void }) => {
  const { userData } = useUserData();

  return (
    <Flex
      sx={{
        padding: "7.4074vh 0 24px",
        height: "100%",
        color: "#fff",
      }}
      flexDir={"column"}
      justify={"space-between"}
      align={"center"}
    >
      <Image
        src={LogoIcon}
        sx={{
          width: "195px",
        }}
      ></Image>
      <Image
        src={ConIcon}
        sx={{
          width: "307px",
          marginTop: "0.8519vh",
        }}
      ></Image>

      <Box
        sx={{
          textAlign: "center",
          lineHeight: "1.2",
        }}
      >
        <Text
          sx={{
            fontSize: "30px",
            fontWeight: "bold",
          }}
        >
          You own
        </Text>
        <Text
          sx={{
            color: "#97E181",
            textAlign: "center",
            fontSize: "60px",
            fontStyle: "normal",
            fontWeight: 700,
          }}
        >
          {userData?.score ? Number(userData.score).toLocaleString() : 0}
        </Text>
        <Text
          sx={{
            fontSize: "30px",
            fontWeight: 700,
          }}
        >
          PEPES
        </Text>
      </Box>

      <Flex
        onClick={() => {
          onStepChange();
        }}
        sx={{
          width: "326px",
          height: "52px",
          padding: "6px 26px",
          background: "#97E181",
          color: "#000",
          borderRadius: "17px",
          margin: "0 auto",
          fontSize: "20px",
          fontWeight: "bold",
          cursor: "pointer",
        }}
        align={"center"}
        justify={"center"}
      >
        Continue
      </Flex>
    </Flex>
  );
};

export default Congrats;
