import Detail from "@/components/Earn/Detail";
import Pool from "@/components/Earn/Pool";
import Header from "@/components/Earn/Header";
import config from "@/constants/tgConfig";
import { waitForTransaction } from "@/utils";
import { formatAmount } from "@/utils/formatBalance";
import { Box } from "@chakra-ui/react";
import { getHttpV4Endpoint } from "@orbs-network/ton-access";
import { Address, TonClient4 } from "@ton/ton";
import { useTonAddress } from "@tonconnect/ui-react";
import { useEffect, useState } from "react";

const Earn = () => {
  const [showDetail, setShowDetail] = useState(false);
  const [pool, setPool] = useState<any>(null);
  const rawAddress = useTonAddress(false);
  const [balance, setBalance] = useState("0");

  const handleShowDetail = (show: boolean, pool: any) => {
    setShowDetail(show);
    setPool(pool);
  };

  const handleBalance = async () => {
    try {
      const endpoint = await getHttpV4Endpoint({
        network: config.network,
      });
      const TON_CLIENT = new TonClient4({ endpoint });
      const lastBlock = await TON_CLIENT.getLastBlock();
      const seqno = lastBlock.last.seqno;
      const accountInfo = await TON_CLIENT.getAccount(
        seqno,
        Address.parse(rawAddress)
      );
      const balance = accountInfo.account.balance.coins;
      setBalance(formatAmount(balance.toString(), 9));
    } catch (e) {
      console.log("get balance error", e);
    }
  };

  useEffect(() => {
    if (!rawAddress) return;
    handleBalance();
    const timer = setInterval(() => {
      handleBalance();
    }, 3000);

    return () => {
      clearInterval(timer);
    };
  }, [rawAddress]);

  return (
    <Box
      sx={{
        height: "100%",
        overflow: "auto",
        paddingBottom: "20px",
        boxSizing: "border-box",
      }}
    >
      <Header
        balance={balance}
        showBack={showDetail}
        onBack={() => {
          handleShowDetail(false, {});
        }}
      ></Header>
      {showDetail ? (
        <Detail
          pool={pool}
          onShowDetail={() => {
            handleShowDetail(false, {});
          }}
        ></Detail>
      ) : (
        <Pool
          onShowDetail={(poolId: number) => {
            handleShowDetail(true, poolId);
          }}
        ></Pool>
      )}
    </Box>
  );
};

export default Earn;
