import { Box, Image, Text } from "@chakra-ui/react";
import PlayDrawer from "./PlayDrawer";
import PlayDrawerWrap from "./PlayDrawer";
import HelpIcon from "./assets/helpicon.png";
const HelpDrawer = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <PlayDrawer isOpen={isOpen} onClose={onClose} title="Help">
      <Box>
        <Image
          src={HelpIcon}
          sx={{
            width: "70px",
          }}
        ></Image>
        <Text
          sx={{
            fontSize: "24px",
            fontWeight: "bold",
            marginTop: "12px",
          }}
        >
          PEPES Spin{" "}
        </Text>
        <Text
          sx={{
            marginTop: "20px",
          }}
        >
          Go big or go home in PEPES Spin — the heart-pounding, winner-takes-all
          battle royale!💰 Deposit $TON and spin the wheels. ⚠️But
          remember:there can be only one winner!And we charge a 5% commission of
          pool prize！
        </Text>
      </Box>
    </PlayDrawer>
  );
};

export default HelpDrawer;
