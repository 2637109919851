import { Flex, Box, Text } from "@chakra-ui/react";

const TopNav = ({
  activeTab,
  setActiveTab,
}: {
  activeTab: number;
  setActiveTab: (tab: number) => void;
}) => {
  return (
    <Flex
      sx={{
        marginTop: "30px",
      }}
    >
      <Box
        sx={{
          color: "#FFF",
          fontSize: "10px",
          fontStyle: "normal",
          fontWeight: 500,
        }}
      >
        <Text>Top earners</Text>
        <Text>(Top 20 daily will be rewarded)</Text>
      </Box>
      <Flex
        gap={"3px"}
        sx={{
          padding: "2px",
          background: "#232523",
          marginLeft: "auto",
          borderRadius: "10px",
        }}
      >
        <Flex
          sx={{
            height: "27px",
            width: "55px",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "9px",
            background: activeTab === 0 ? "#FFF" : "#4C4C4C",
            color: activeTab === 0 ? "#000" : "#FFF",
            fontSize: "10px",
            fontWeight: 500,
            cursor: "pointer",
            transition: "all 0.3s ease",
          }}
          onClick={() => setActiveTab(0)}
        >
          All-time
        </Flex>
        <Flex
          sx={{
            height: "27px",
            width: "55px",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "9px",
            background: activeTab === 1 ? "#FFF" : "#4C4C4C",
            color: activeTab === 1 ? "#000" : "#FFF",
            fontSize: "10px",
            fontWeight: 500,
            cursor: "pointer",
            transition: "all 0.3s ease",
          }}
          onClick={() => setActiveTab(1)}
        >
          Daily
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TopNav;
