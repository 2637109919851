import {
  checkTx,
  getBetRingGame,
  getGameHistory,
  getGameIngo,
  getTonPrice,
  testBet,
} from "@/api/game";
import Header from "@/components/Play/Header";
import Players from "@/components/Play/Players";
import RoundDetail from "@/components/Play/RoundDetail";
import RoundEnter from "@/components/Play/RoundEnter";
import RoundInfo from "@/components/Play/RoundInfo";
import SelectBet from "@/components/Play/SelectBet";
import { accMul, formatAmount } from "@/utils/formatBalance";
import { getCheckTx, getTxByBOC } from "@/utils/getTxByBoc";
import { Box, Button, Image, Flex, Text } from "@chakra-ui/react";
import { Address, beginCell, Cell, toNano } from "@ton/core";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import ConfirmY from "@/components/Play/assets/confirm-y.png";
import toast from "react-hot-toast";
import { getHttpV4Endpoint } from "@orbs-network/ton-access";
import { TonClient4 } from "@ton/ton";
import config from "@/constants/tgConfig";

export const amountsList = [
  {
    value: 0.5,
    label: "0.5",
  },
  {
    value: 1,
    label: "1",
  },

  {
    value: 3,
    label: "3",
  },
  {
    value: 5,
    label: "5",
  },
];
export interface GameInfo {
  id: number;
  status: number;
  winnerUser: number;
  winnerBetAmount: string;
  player: number;
  totalRewardScore: number;
  totalBetAmount: string;
  createTime: string;
  betHistorys: any[];
}

const network = config.network;

const Play = () => {
  const [zhuan, setZhuan] = useState<{
    [roundId: number]: boolean;
  }>({});
  const [tonConnectUI] = useTonConnectUI();
  const [loading, setLoading] = useState(false);

  const [count, setCount] = useState(1);
  const [showRoundResult, setShowRoundResult] = useState(false); //展示轮次结果
  const [showRoundHistory, setShowRoundHistory] = useState(false);
  const [showBet, setShowBet] = useState(false);
  const [selectAmountIndex, setSelectAmountIndex] = useState(-1);
  const [currentRound, setCurrentRound] = useState(0);
  const [lastRound, setLastRound] = useState(0);
  const [tonPrice, setTonPrice] = useState("0");
  const [balance, setBalance] = useState("0.00");

  console.log(`currentId: ${currentRound}, new Id: ${lastRound}`);

  const [gameInfo, setGameInfo] = useState({
    id: 0,
    status: 0,
    winnerUser: 0,
    winnerBetAmount: "0",
    player: 0,
    totalRewardScore: 0,
    totalBetAmount: "0",
    createTime: "0",
    betHistorys: [],
  });

  // 展示历史记录
  const handleShowRoundHistory = () => {
    setShowRoundHistory(!showRoundHistory);
  };

  const handleShowRoundResult = (flag: boolean) => {
    setShowRoundResult(flag);
  };

  const handleShowBet = () => {
    setShowBet(!showBet);
  };

  const handleGetRingGameInfo = async () => {
    const res = await getGameIngo(currentRound);
    if (res.code !== 200) {
      return;
    }
    setGameInfo(res.data);
  };

  const handleSelectAmount = (index: number) => {
    setSelectAmountIndex(index);
  };

  const handleZhuan = (id: number, flag: boolean) => {
    setZhuan({
      [id]: flag,
    });
  };

  const handleBet = async () => {
    try {
      const _count = count;
      const allAmount = accMul(
        String(amountsList[selectAmountIndex].value),
        _count
      );
      setLoading(true);
      const tonData = await getBetRingGame({
        amount: allAmount,
        count: _count,
      });
      if (tonData.code == 200) {
        const comment = tonData.data.comment;
        const transaction = {
          validUntil: Math.floor(Date.now() / 1000) + 60 * 60 * 24 * 7, // 7 days
          messages: [
            {
              address: tonData.data.inAddress, // destination address
              amount: toNano(allAmount).toString(), //Toncoin in nanotons
              payload: beginCell()
                .storeUint(0, 32)
                .storeStringTail(comment)
                .endCell()
                .toBoc()
                .toString("base64"),
            },
          ],
        };
        const res = await tonConnectUI.sendTransaction(transaction, {
          notifications: [],
        });
        const exBoc = res.boc;

        const extHash = Cell.fromBase64(exBoc).hash().toString("hex");

        const hash = await getTxByBOC(exBoc, rawAddress);

        await getCheckTx(hash);

        setShowBet(false);
        toast(
          <Flex
            align={"flex-start"}
            sx={{
              padding: "0 0 0 12px",
              background: "#97E181",
              borderRadius: "20px",
              border: "none !important",
              overflow: "hidden",
            }}
          >
            <Flex
              className="test"
              sx={{
                textAlign: "center",
                background: "#232523",
                padding: "12px",
                margin: "0 !important",
                borderRadius: "20px",
                border: "none !important",
                color: "#fff",
              }}
              align={"flex-start"}
            >
              <Image
                src={ConfirmY}
                sx={{
                  width: "26px",
                  height: "26px",
                  marginRight: "8px",
                }}
              ></Image>
              <Box
                sx={{
                  flex: 1,
                }}
              >
                <Text
                  sx={{
                    fontSize: "15px",
                    fontStyle: "normal",
                    fontWeight: 900,
                    textAlign: "left",
                    marginTop: "4px",
                  }}
                >
                  Entered Round!
                </Text>
                <Text
                  sx={{
                    fontSize: "13px",
                    textAlign: "left",
                    fontWeight: 500,
                    marginTop: "4px",
                  }}
                >
                  You’ve successfully entered your assets to the current
                  round.Good luck!
                </Text>
              </Box>
            </Flex>
          </Flex>,
          {
            duration: 3000,
            position: "top-center",
            style: {
              background: "transparent",
              borderRadius: "20px",
              padding: "0 0 0 0",
              margin: "0 !important",
              width: "100%",
              border: "none",
            },
          }
        );
      }
      setLoading(false);
    } catch (e) {
      console.log(e, "e");
      setLoading(false);
    }
  };

  const handleTestBet = async () => {
    try {
      const list = [
        1202892202, 1210346065, 1840793381, 1978888931, 5000284026, 5466254439,
        5741292133, 6180185529, 6418266900,
      ];
      const index = Math.floor(Math.random() * list.length);
      const res = await testBet({
        amount: String(amountsList[0].value),
        count: 1,
        userId: String(list[index]),
      });
      handleGetRingGameInfo();
      console.log(res, "res");
    } catch (e) {
      console.log(e, "e");
    }
  };

  // 获取最新一轮的id
  const handleLastRound = async () => {
    const res = await getGameIngo();
    if (res.code !== 200) {
      return;
    }
    const gameInfo = res.data;
    const time = dayjs.utc(gameInfo.createTime).local().valueOf();
    // console.log(
    //   "后端返回的开始时间",
    //   dayjs.utc(gameInfo.createTime).local().valueOf()
    // );

    // console.log("解析后中国时间", dayjs(time).format("YYYY-MM-DD HH:mm:ss"));
    // console.log("当前时间", dayjs().format("YYYY-MM-DD HH:mm:ss"));

    return res.data.id;
  };

  const handleToRound = (id: number, zhuan?: boolean) => {
    setCurrentRound(id);
    setGameInfo({
      id: id,
      status: 0,
      winnerUser: 0,
      winnerBetAmount: "0",
      player: 0,
      totalRewardScore: 0,
      totalBetAmount: "0",
      createTime: "0",
      betHistorys: [],
    });
    if (zhuan) {
      handleZhuan(Number(lastRound), true);
    } else {
    }
  };

  useEffect(() => {
    handleLastRound().then((id) => {
      setZhuan({
        [id]: true,
      });
      setCurrentRound(id);
      setLastRound(id);
    });

    const timer = setInterval(() => {
      handleLastRound().then((id) => {
        setLastRound(id);
      });
    }, 3000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (currentRound == 0) return;

    handleGetRingGameInfo();
    const timer = setInterval(() => {
      handleGetRingGameInfo();
    }, 3000);

    return () => {
      clearInterval(timer);
    };
  }, [currentRound]);

  useEffect(() => {
    getTonPrice().then((res) => {
      setTonPrice(res.prices[0][1]);
    });
  }, []);

  const rawAddress = useTonAddress();

  const handleBalance = async () => {
    try {
      const endpoint = await getHttpV4Endpoint({
        network: network,
      });
      const TON_CLIENT = new TonClient4({ endpoint });
      const lastBlock = await TON_CLIENT.getLastBlock();
      const seqno = lastBlock.last.seqno;
      const accountInfo = await TON_CLIENT.getAccount(
        seqno,
        Address.parse(rawAddress)
      );
      const balance = accountInfo.account.balance.coins;
      setBalance(formatAmount(balance.toString(), 9));
    } catch (e) {
      console.log("get balance error", e);
    }
  };

  useEffect(() => {
    if (!rawAddress) return;
    handleBalance();
    const timer = setInterval(() => {
      handleBalance();
    }, 3000);

    return () => {
      clearInterval(timer);
    };
  }, [rawAddress]);

  return (
    <Box
      sx={{
        color: "#fff",
        height: "100%",
        overflow: "auto",
        padding: "20px 20px 0",
      }}
    >
      <Header balance={balance}></Header>
      <Box
        sx={{
          marginTop: "20px",
        }}
      >
        {/* <Button onClick={handleTestBet}>测试bet</Button> */}
        {!showRoundHistory && (
          <Box
            sx={{
              height: "100%",
              paddingBottom: "180px",
            }}
          >
            {showBet ? (
              <Box>
                <SelectBet
                  balance={balance}
                  tonPrice={tonPrice}
                  count={count}
                  onCountChange={(count) => {
                    setCount(count);
                  }}
                  showTip={
                    gameInfo.id < lastRound ||
                    (gameInfo.id == lastRound && gameInfo.status >= 2)
                  }
                  onShowBet={handleShowBet}
                  selectAmountIndex={selectAmountIndex}
                  onSelectAmount={handleSelectAmount}
                ></SelectBet>
              </Box>
            ) : (
              <Box>
                <RoundInfo
                  onShowRoundHistory={handleShowRoundHistory}
                  onToRound={handleToRound}
                  lastRound={lastRound}
                  onZhuan={handleZhuan}
                  zhuan={zhuan}
                  gameInfo={gameInfo}
                  showRoundResult={showRoundResult}
                  onShowRoundResult={handleShowRoundResult}
                ></RoundInfo>
                <Players
                  showRoundResult={showRoundResult}
                  gameInfo={gameInfo}
                ></Players>
              </Box>
            )}
            <RoundEnter
              loading={loading}
              onToRound={handleToRound}
              currentRound={currentRound}
              lastRound={lastRound}
              onBet={handleBet}
              selectAmountIndex={selectAmountIndex}
              gameInfo={gameInfo}
              showBet={showBet}
              onShowBet={handleShowBet}
            ></RoundEnter>
          </Box>
        )}
        {showRoundHistory && (
          <Box
            sx={{
              height: "100%",
            }}
          >
            <RoundDetail
              onShowRoundHistory={handleShowRoundHistory}
            ></RoundDetail>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default Play;
