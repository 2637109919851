import React, { Fragment, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import {
  Box,
  ChakraProvider,
  ColorModeScript,
  useMediaQuery,
} from "@chakra-ui/react";
import { Global } from "@emotion/react";
import { BrowserRouter } from "react-router-dom";
import { GlobalStyles } from "./constants";
import AppRoutes from "./Routes";
import theme from "./theme";
import { SDKProvider } from "@tma.js/sdk-react";
import { UserDataProvider } from "./contexts/SubmitRequest";
import { NotificationsProvider } from "reapop";

const RootContent = () => {
  return (
    <ChakraProvider theme={theme}>
      <Global styles={GlobalStyles} />
      <BrowserRouter>
        <NotificationsProvider>
          <UserDataProvider>
            <SDKProvider acceptCustomStyles debug>
              <AppRoutes />
            </SDKProvider>
          </UserDataProvider>
        </NotificationsProvider>
      </BrowserRouter>
    </ChakraProvider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Box sx={{ height: "100%" }}>
    <ColorModeScript />
    <RootContent></RootContent>
  </Box>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
