import {
  Box,
  Flex,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import WalletIcon from "./assets/walletIcon.png";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import { shortenAddress } from "@/utils/address";
import BlackLogoIcon from "@/assets/black-logo.png";
import TonIcon from "@/components/Play/assets/b-tonicon.png";
import { toFixed } from "@/utils/formatBalance";

const ConnectWallet = ({ balance, sx }: { balance: string; sx?: any }) => {
  const [tonConnectUI, setOptions] = useTonConnectUI();
  const rawAddress = useTonAddress(true);
  return (
    <Box
      sx={{
        ...sx,
      }}
    >
      {rawAddress ? (
        <Popover autoFocus={true} closeOnBlur={true}>
          <PopoverTrigger>
            <Flex
              align={"center"}
              justify={"center"}
              sx={{
                height: "33px",
                borderRadius: "10px",
                border: "1px solid  #97E181",
                background: "#fff",
                padding: "0 8px",
              }}
            >
              <Image
                src={WalletIcon}
                sx={{
                  width: "26px",
                }}
              ></Image>
              <Box
                sx={{
                  width: "1px",
                  height: "14px",
                  background: "#000",
                }}
              ></Box>
              <Image
                src={TonIcon}
                sx={{
                  width: "16px",
                  margin: "0 4px",
                }}
              ></Image>
              <Text
                sx={{
                  fontSize: "15px",
                  fontWeight: 500,
                  color: "#000",
                }}
              >
                {toFixed(balance, 2)}
              </Text>
            </Flex>
          </PopoverTrigger>
          <PopoverContent
            sx={{
              border: "none",
              background: "#fff",
              color: "#000",
              width: "178px",
              "&:focus-visible": {
                boxShadow: "none",
              },
            }}
          >
            <PopoverBody>
              <Box>
                <Flex align={"center"}>
                  <Image
                    src={BlackLogoIcon}
                    sx={{
                      width: "20px",
                      height: "20px",
                      marginRight: "8px",
                    }}
                  ></Image>
                  <Text
                    sx={{
                      fontSize: "10px",
                    }}
                  >
                    {shortenAddress(rawAddress, 6, 4)}
                  </Text>
                </Flex>
                <Flex
                  align={"center"}
                  justify={"center"}
                  sx={{
                    width: "151px",
                    height: "27px",
                    borderRadius: "14px",
                    background: "#000",
                    color: "#fff",
                    fontSize: "10px",
                    marginTop: "5px",
                  }}
                  onClick={() => {
                    tonConnectUI.disconnect();
                  }}
                >
                  Disconnect
                </Flex>
              </Box>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      ) : (
        <Flex
          sx={{
            width: "137px",
            height: "33px",
            justifyContent: "center",
            alignItems: "center",
            background: "transparent",
            fontSize: "15px",
            color: "#fff",
            borderRadius: "10px",
            border: "1px solid #4C4C4C",
          }}
          onClick={() => {
            tonConnectUI.openModal();
          }}
        >
          Connect Wallet
        </Flex>
      )}
    </Box>
  );
};

export default ConnectWallet;
