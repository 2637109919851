import axios from "axios";

console.log(process.env.REACT_APP_ENV, "process.env.REACT_APP_ENV");
export const api = axios.create({
  baseURL:
    process.env.REACT_APP_ENV == "production"
      ? "https://api.tonpepes.xyz/api" //https://api.tonpepes.xyz/api
      : "https://api.test.tonpepes.xyz/api",
});

api.interceptors.response.use(
  (response) => {
    if (response.data.code === 500) {
      return Promise.reject(response.data);
    }

    return response;
  },
  (error) => {
    if (error.response.status === 401) {
    }
    return Promise.reject(error.response);
  }
);

export const login = async (initData: any) => {
  const response = await api.post("/User/Login", initData, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const getLeaderboard = async () => {
  const token = sessionStorage.getItem("jwtToken");
  const response = await api.get(`/User/GetUserScoreRank/500/1`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getInvite = async () => {
  const token = sessionStorage.getItem("jwtToken");
  const response = await api.get(`/User/GetUserInviteUser/500/1`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getSuccessTask = async () => {
  const token = sessionStorage.getItem("jwtToken");
  const response = await api.get(`/User/SuccessTask`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getLoginAward1 = async () => {
  const token = sessionStorage.getItem("jwtToken");
  const response = await api.get(`/User/LoginAward1/178`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const doLoginAward = async (unixTime?: number) => {
  const token = sessionStorage.getItem("jwtToken");
  if (unixTime) {
    const response = await api.post(
      `/User/DebugLoginAward/178/${unixTime}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } else {
    const response = await api.post(
      `/User/DoTask/178`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
};

export const dotask = async (scoreType: number) => {
  const token = sessionStorage.getItem("jwtToken");
  const response = await api.post(
    `/User/DoTask/${scoreType}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const getScoreHistory = async (pageIndex: number) => {
  const token = sessionStorage.getItem("jwtToken");
  const response = await api.get(`/User/GetUserScoreHistory/20/${pageIndex}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const connectionWallet = async (payload: any) => {
  const token = sessionStorage.getItem("jwtToken");
  const response = await api.post(`/User/ConnectionWallet`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDailyReward = async (day: string) => {
  const token = sessionStorage.getItem("jwtToken");
  const response = await api.get(`/User/DayScoreRank/${day}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getConnectWalletCount = async () => {
  const token = sessionStorage.getItem("jwtToken");
  const response = await api.get(`/User/ConnectWalletCount`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
