import { Flex, Image, Text } from "@chakra-ui/react";
import ConnectWallet from "../Button/ConnectWallet";
import BackIcon from "./assets/back-icon.png";

const Header = ({
  showBack,
  balance,
  onBack,
}: {
  showBack: boolean;
  balance: string;
  onBack: () => void;
}) => {
  return (
    <Flex
      sx={{
        width: "100%",
        zIndex: 1000,
        height: "60px",
        padding: "0 12px",
      }}
      align={"center"}
    >
      {showBack && (
        <Flex
          sx={{
            cursor: "pointer",
            marginRight: "auto",
          }}
          onClick={onBack}
        >
          <Image
            src={BackIcon}
            sx={{
              marginRight: "5px",
              width: "24px",
              height: "24px",
            }}
          ></Image>
          <Text
            sx={{
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 900,
              color: "#FFF",
            }}
          >
            BACK
          </Text>
        </Flex>
      )}
      <ConnectWallet
        balance={balance}
        sx={{
          marginLeft: "auto",
        }}
      ></ConnectWallet>
    </Flex>
  );
};

export default Header;
