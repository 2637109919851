import { css } from "@emotion/react";

export const GlobalStyles = css`
  @font-face {
    font-family: "Roboto";
    src: url("/Roboto-Regular.ttf") format("opentype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Roboto";
    src: url("/Roboto-Blod.ttf") format("opentype");
    font-weight: blod;
  }
  @font-face {
    font-family: "ArchivoBlack";
    src: url("/ArchivoBlack.ttf") format("opentype");
    font-weight: normal;
    font-style: normal;
  }

  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: "Roboto", sans-serif;
  }
  html {
    height: 100%;
  }
  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
  }
  #root {
    height: 100%;
  }
`;
