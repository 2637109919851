import { Box, Flex, Image, Text } from "@chakra-ui/react";
import PlayDrawer from "./PlayDrawer";
import ReferralIcon from "./assets/referralicon.png";
import CopyIcon from "./assets/copy.png";
import { useClipboard } from "@chakra-ui/react";
import config from "@/constants/tgConfig";
import { useUserData } from "@/contexts/SubmitRequest";
import { useEffect, useState } from "react";
import { getInviteUserReward } from "@/api/game";
import { avatarImg } from "@/utils/avatars";

const ReferralDrawer = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { userData } = useUserData();
  const { hasCopied, onCopy, setValue, value } = useClipboard(``);
  const [inviteList, setInviteList] = useState([]);

  const handleGetInvite = async () => {
    try {
      const res = await getInviteUserReward();
      if (res.code === 200) {
        setInviteList(res.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (userData?.userId) {
      setValue(`${config.MINI_APP_URL}?startapp=${userData.userId}`);
    }
  }, [userData]);

  useEffect(() => {
    handleGetInvite();
  }, []);

  return (
    <PlayDrawer isOpen={isOpen} onClose={onClose} title="Referrals">
      <Box
        sx={{
          paddingBottom: "30px",
          color: "#fff",
        }}
      >
        <Image
          src={ReferralIcon}
          sx={{
            width: "70px",
          }}
        ></Image>
        <Text
          sx={{
            fontSize: "24px",
            fontWeight: "bold",
            marginTop: "12px",
          }}
        >
          Referral
        </Text>
        <Text
          sx={{
            marginTop: "10px",
            fontSize: "13px",
          }}
        >
          Invite a friend to earn 10% of the points they make. And if your
          friend refers another friend, you'll earn extra 5% of their points,
          too!
        </Text>
        <Box
          sx={{
            borderRadius: "17px",
            border: "1px solid  #97E181",
            padding: "10px 15px",
            marginTop: "25px",
          }}
        >
          <Text
            sx={{
              color: "#A1A1A1",
              fontSize: "13px",
            }}
          >
            You Referral Link
          </Text>
          <Flex
            sx={{ marginTop: "4px" }}
            justify={"space-between"}
            align={"center"}
          >
            <Text
              sx={{
                width: "200px",
                fontSize: "13px",
              }}
            >
              {value}
            </Text>
            <Image
              src={CopyIcon}
              sx={{ width: "34px" }}
              onClick={() => {
                onCopy();
              }}
            ></Image>
          </Flex>
        </Box>

        <Flex
          flexDir={"column"}
          gap={"10px"}
          sx={{
            marginTop: "20px",
            maxHeight: "200px",
            overflowY: "auto",
          }}
        >
          {inviteList.map((item, index) => {
            return (
              <Flex key={index} align={"center"}>
                <Image
                  src={avatarImg(item.userId)}
                  sx={{
                    width: "36px",
                    height: "36px",
                    marginRight: "12px",
                  }}
                ></Image>
                <Box sx={{}}>
                  <Text
                    sx={{
                      fontSize: "15px",
                      fontStyle: "normal",
                      fontWeight: 900,
                    }}
                  >
                    {item.firstName} {item.lastName}
                  </Text>
                  <Text
                    sx={{
                      color: "#A1A1A1",
                    }}
                  >
                    {Number(item.rewardScore).toLocaleString()}{" "}
                    <span
                      style={{
                        fontSize: "10px",
                        fontWeight: 500,
                      }}
                    >
                      PEPES
                    </span>{" "}
                  </Text>
                </Box>
              </Flex>
            );
          })}
        </Flex>
      </Box>
    </PlayDrawer>
  );
};

export default ReferralDrawer;
