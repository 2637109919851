const tgConfig = {
  MINI_APP_URL: "t.me/TONPEPES_BOT/PEPES",
  GROUP_URL: "https://t.me/Pepes_vibe",
  TW_URL: "https://x.com/TonPepes",
  endpoint: "https://toncenter.com/api/v2/jsonRPC",
  apiKey: "8e2558f85f2a5046a11346f83171e75cf0f855fbb1c1fec76af9132409c84019",
  network: "mainnet",
  usdt: "EQBynBO23ywHy_CgarY9NK9FTz0yDsG82PtcbSTQgGoXwiuA",
};

const devConfig = {
  MINI_APP_URL: "t.me/HandsomeFFFFatherBot/pepepe",
  GROUP_URL: "https://t.me/Pepes_vibe",
  TW_URL: "https://x.com/TonPepes",
  endpoint: "https://testnet.toncenter.com/api/v2/jsonRPC",
  apiKey: "c9d11d7f6e444dbc3dc297293074e76b9c2f89a62ca08d1af5ba6ce5ee7169a3",
  network: "testnet",
  usdt: "EQDkJnJjZoxmn1dfbYTqieIOJtIw0matL3EYpGE5_dEZdJQy",
};

const config: any =
  process.env.REACT_APP_ENV == "production" ? tgConfig : devConfig;

export default config;
