const roundColors = [
  "#de41d7",
  "#e0db56",
  "#55c5d4",
  "#e64faa",
  "#b58e2b",
  "#db3e3e",
  "#64f5c1",
  "#bb118c",
  "#39b1d7",
  " #d86656",
  "#55dada",
  "#d6b81b",
  "#f57f5a",
  "#eca53b",
  "#53ea7d",
  "#4429b0",
  "#6bf371",
  "#4ccd2b",
  "#e36219",
  " #e12a3f",
  "#c50e50",
  "#d7e717",
  "#6ea825",
  "#46d75d",
  "#5a91e3",
  "#43e6bf",
  "#4aba15",
  "#60c311",
  "#139ae5",
  " #13ec6a",
  "#6066e8",
  "#ad18ba",
  "#5369db",
  "#813fd5",
  "#6cb0ef",
  "#790cce",
  "#66f5e4",
  "#b835d9",
  "#966bf1",
  " #54de48",
  "#b855e6",
  "#e35e7e",
  "#f04b9a",
  "#f458db",
  "#1247d0",
  "#62d89f",
  "#3b2fc2",
  "#bbe957",
  "#c3da66",
  " #cb7628",
];

export default roundColors;
