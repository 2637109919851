import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import LogoIcon from "@/assets/logo.svg";

const Introduce = ({ onStepChange }: { onStepChange: () => void }) => {
  return (
    <Flex
      sx={{
        height: "100%",
        padding: "80px 0 24px",
      }}
      justify={"space-between"}
      align={"center"}
      flexDir={"column"}
    >
      <Image
        src={LogoIcon}
        sx={{
          width: "195px",
        }}
      ></Image>
      <Flex
        sx={{
          padding: "0 30px",
        }}
      >
        <Text
          sx={{
            fontSize: "15px",
            textAlign: "center",
            width: "300px",
            color: "#fff",
            fontWeight: "bold",
          }}
        >
          🐸This is a local PEPE on Telegram. Everyone should join it!
        </Text>
      </Flex>{" "}
      <Flex
        onClick={() => {
          onStepChange();
        }}
        sx={{
          width: "326px",
          height: "52px",
          padding: "6px 26px",
          background: "#97E181",
          color: "#000",
          borderRadius: "17px",
          margin: "0 auto",
          fontSize: "20px",
          fontWeight: "bold",
          cursor: "pointer",
        }}
        align={"center"}
        justify={"center"}
      >
        Continue
      </Flex>
    </Flex>
  );
};

export default Introduce;
