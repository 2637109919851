import { useEffect, useMemo, useState } from "react";
import { Box, Flex, Image, Text, useDisclosure } from "@chakra-ui/react";
import AgeJson from "@/assets/21_party.json";
import CheckJson from "@/assets/22_party.json";
import SocialJson from "@/assets/24_party.json";
import FriendJson from "@/assets/26_party.json";
import TelegramJson from "@/assets/25_party.json";
import SpinJson from "@/components/Home/assets/spinJson.json";
import HomeBg from "@/assets/homeBg.png";
import HomeBg2 from "@/assets/homeBg2.png";
import HomeBg3 from "@/assets/homeBg3.png";

import { useUserData } from "@/contexts/SubmitRequest";
import { doLoginAward, getLoginAward1 } from "@/api";
import { useNavigate } from "react-router-dom";
import SignInModal from "@/components/SignInModal";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import DisconnectIcon from "@/assets/disconnect.svg";
import { shortenAddress } from "@/utils/address";
import Lottie from "lottie-react";
import RingInfo from "@/components/Home/RingInfo";
dayjs.extend(utc);

export const startTime = dayjs(1730764800000).utc();

const RewardWrapper = ({
  iconJson,
  name,
  score,
}: {
  iconJson: any;
  name: string;
  score: any;
}) => {
  return (
    <Flex
      sx={{
        fontSize: "15px",
        color: "#fff",
        height: "25px",
        padding: "0 8px",
        width: "100%",
      }}
      align={"center"}
      justify={"space-between"}
    >
      <Flex align={"center"}>
        <Lottie
          style={{
            marginRight: "10px",
            width: "28px",
          }}
          animationData={iconJson}
          loop={true}
        />
        <Text>{name}</Text>
      </Flex>
      <Text>
        + {Number(score).toLocaleString()}{" "}
        <span
          style={{
            fontSize: "10px",
          }}
        >
          PEPES
        </span>{" "}
      </Text>
    </Flex>
  );
};

// 1注册时长
// 2用户会员
// 3邀请好友
// 4加入tg
// 5加入tw
// 6名字加入pepe
// 7bio加入pepe
const Home = () => {
  const { isOpen, onOpen, onClose } = useDisclosure({});
  const {
    isOpen: walletOpen,
    onOpen: walletOnOpen,
    onClose: walletOnClose,
  } = useDisclosure({});

  const navigate = useNavigate();
  const [ageScore, setAgeScore] = useState(0);
  const [friendScore, setFriendScore] = useState(0);
  const [socialScore, setSocialScore] = useState(0);
  const [taskScore, setTaskScore] = useState(0);
  const [tgpremiumScore, setTgpremiumScore] = useState(0);
  const [ringScore, setRingScore] = useState(0);
  const [tonConnectUI, setOptions] = useTonConnectUI();
  const rawAddress = useTonAddress(true);
  const { userData, completeList, handleGetSuccessTask } = useUserData();
  const [totalScore, setTotalScore] = useState(0);
  const [loginAward, setLoginAward] = useState<any>({});
  const [count, setCount] = useState(0);
  useEffect(() => {
    let ageScore = 0;
    let friendScore = 0;
    let socialScore = 0;
    let tgpremiumScore = 0;
    let taskScore = 0;
    let total = 0;
    let ringScore = 0;
    completeList.forEach((element: any) => {
      if (element.changeType == 162 || element.changeType == 163) {
        ringScore += element.score;
      } else if (element.changeType == 1) {
        ageScore = element.score;
      } else if (element.changeType == 2) {
        tgpremiumScore = element.score;
      } else if (element.changeType == 3) {
        friendScore = element.score;
      } else if (
        element.changeType == 4 ||
        element.changeType == 5 ||
        element.changeType == 6 ||
        element.changeType == 7 ||
        element.changeType == 176 ||
        element.changeType == 177 ||
        element.changeType == 178
      ) {
        socialScore += element.score;
      } else if (
        element.changeType == 9 ||
        element.changeType == 10 ||
        element.changeType == 11 ||
        element.changeType == 12 ||
        element.changeType == 13 ||
        element.changeType == 14 ||
        element.changeType == 15 ||
        element.changeType == 16 ||
        element.changeType == 17 ||
        element.changeType == 18 ||
        element.changeType == 19 ||
        element.changeType == 20 ||
        element.changeType == 21 ||
        element.changeType == 22 ||
        element.changeType == 23 ||
        element.changeType == 24 ||
        element.changeType == 25 ||
        element.changeType == 26 ||
        element.changeType == 27 ||
        element.changeType == 28 ||
        element.changeType == 29 ||
        element.changeType == 30 ||
        element.changeType == 31 ||
        element.changeType == 32 ||
        element.changeType == 33 ||
        element.changeType == 34 ||
        element.changeType == 35 ||
        element.changeType == 36 ||
        element.changeType == 37 ||
        element.changeType == 38 ||
        element.changeType == 39 ||
        element.changeType == 40 ||
        element.changeType == 41 ||
        element.changeType == 42 ||
        element.changeType == 43
      ) {
        taskScore += element.score;
      }
      setAgeScore(ageScore);
      setFriendScore(friendScore);
      setSocialScore(socialScore);
      setTgpremiumScore(tgpremiumScore);
      setTaskScore(taskScore);
      setRingScore(ringScore);
      total += element.score;
    });
    setTotalScore(total);
  }, [completeList]);

  const handleGetSignIn = async () => {
    try {
      const now = dayjs().utc();

      const res = await getLoginAward1();
      if (res.code !== 200) {
        return;
      }

      setLoginAward(res.data);
      const localOpen = sessionStorage.getItem("signopen");

      if (now.valueOf() < startTime.valueOf()) {
        return;
      }
      const diff = now.diff(startTime, "day");
      if (diff > 6) {
        return;
      }
      if (!res.data[diff] && !localOpen) {
        setTimeout(() => {
          onOpen();
        }, 1500);
        sessionStorage.setItem("signopen", "1");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleLoginReward = async (unixTime?: number) => {
    try {
      const res = await doLoginAward(unixTime);
      handleGetSignIn();
      handleGetSuccessTask();

      if (res.code == 200) {
        return true;
      }
      return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  useEffect(() => {
    handleGetSuccessTask();
    handleGetSignIn();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setCount((count) => count + 1);
    }, 3000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Flex
      sx={{
        height: "100%",
        width: "100%",
        padding: "80px 0 60px",
        overflowY: "auto",
        position: "relative",

        backgroundImage: `url(${HomeBg3})`,
        backgroundSize: "100%",
        // background: "rgba(0, 0, 0, 0.5)",
      }}
      align={"center"}
      flexDir={"column"}
    >
      <Flex
        sx={{
          position: "absolute",
          top: "200px",
          left: "0",
          width: "100%",
          minHeight: "calc(100% - 200px)",
          background:
            "linear-gradient(0deg, #000 83.58%, rgba(102, 102, 102, 0.00) 100%)",
          // overflowY: "auto",
          padding: "0 28px",
        }}
        flexDir={"column"}
        align={"center"}
      >
        <Text
          sx={{
            fontSize: "35px",
            fontWeight: 700,
            marginTop: "20px",
            color: "#fff",
          }}
        >
          {Number(totalScore).toLocaleString()}
        </Text>
        <Text
          sx={{
            color: "#7C7C7C",
            textAlign: "center",
            fontFamily: "Avenir",
            fontSize: "20px",
            fontWeight: 500,
          }}
        >
          $PEPE
        </Text>
        {
          <Box
            sx={{
              width: "100%",
              height: "83px",
              marginTop: "16px",
            }}
          >
            <Box
              sx={{
                background: "#97E181",
                borderRadius: "17px",
                overflow: "hidden",
                width: "100%",
                height: walletOpen ? "83px" : "47px",
                transition: "all 0.1s",
              }}
            >
              <Flex
                sx={{
                  width: "100%",
                  height: "47px",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#FFFFFF",
                  fontSize: "15px",
                  fontWeight: 700,
                  color: "#000",
                  borderRadius: "17px",
                }}
                onClick={() => {
                  if (rawAddress) {
                    if (walletOpen) {
                      walletOnClose();
                    } else {
                      walletOnOpen();
                    }
                  } else {
                    tonConnectUI.openModal();
                  }
                }}
              >
                {rawAddress
                  ? shortenAddress(rawAddress, 6, 4)
                  : "Connect Wallet"}
              </Flex>
              <Flex
                align={"center"}
                justify={"center"}
                gap={"10px"}
                sx={{
                  height: "36px",
                }}
                onClick={() => {
                  tonConnectUI.disconnect();
                  walletOnClose();
                }}
              >
                <Image src={DisconnectIcon}></Image>
                <Text>DisconnectIcon</Text>
              </Flex>
            </Box>
          </Box>
        }

        <Flex
          sx={{
            width: "100%",
            marginTop: "20px",
          }}
          flexDir={"column"}
          gap={"20px"}
        >
          <Flex
            sx={{
              fontSize: "15px",
              color: "#fff",
              background: "#232523",
              height: "57px",
              borderRadius: "15px",
              padding: "0 8px",
              width: "100%",
            }}
            onClick={() => {
              onOpen();
            }}
            align={"center"}
            justify={"space-between"}
          >
            <Flex align={"center"}>
              <Lottie
                style={{
                  marginRight: "10px",
                  width: "28px",
                }}
                animationData={CheckJson}
                loop={true}
              />

              <Text>Check-in</Text>
            </Flex>
            <Flex
              sx={{
                gap: "8px",
              }}
            >
              {[0, 1, 2, 3, 4, 5, 6].map((item, index) => {
                const now = dayjs().utc();
                const diff = now.diff(startTime, "day");
                let status = "default";
                if (loginAward[index]) {
                  status = "signed";
                } else if (index < diff) {
                  status = "past";
                }

                return (
                  <Box
                    key={index}
                    sx={{
                      width: "4px",
                      height: "23px",
                      borderRadius: "5px",
                      background:
                        status == "signed"
                          ? "#97E181"
                          : status == "past"
                          ? "#4C4C4C"
                          : "#fff",
                    }}
                  ></Box>
                );
              })}
            </Flex>
          </Flex>
        </Flex>
        <RingInfo></RingInfo>
        <Flex
          sx={{
            width: "100%",
            marginTop: "20px",
            background: "#232523",
            borderRadius: "15px",
            padding: "8px 0 ",
          }}
          onClick={() => {
            if (count >= 3) {
              navigate("/play");
              return;
            }
            setCount((count) => count + 1);
          }}
          gap={"10px"}
          flexDir={"column"}
        >
          <RewardWrapper
            name="Account age"
            iconJson={AgeJson}
            score={ageScore}
          ></RewardWrapper>
          <RewardWrapper
            name="Social Tasks"
            iconJson={SocialJson}
            score={socialScore}
          ></RewardWrapper>
          <RewardWrapper
            name="Friends rewards"
            iconJson={FriendJson}
            score={friendScore}
          ></RewardWrapper>
          <RewardWrapper
            name="Telegram Premium"
            iconJson={TelegramJson}
            score={tgpremiumScore}
          ></RewardWrapper>
          <RewardWrapper
            name="Spin Rewards"
            iconJson={SpinJson}
            score={ringScore}
          ></RewardWrapper>
        </Flex>
      </Flex>

      <SignInModal
        isOpen={isOpen}
        onClose={onClose}
        loginAward={loginAward}
        handleLoginReward={(unixTime?: number) => {
          return handleLoginReward(unixTime);
        }}
      ></SignInModal>
    </Flex>
  );
};

export default Home;
