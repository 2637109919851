import { Box, Flex, Text, Image } from "@chakra-ui/react";
import { GameInfo } from "@/pages/Play";
import { useMemo } from "react";
import { accAdd, accDiv, accMul, toFixed } from "@/utils/formatBalance";
import roundColors from "./roundColors";
import TonIcon from "./assets/tonicon.png";
import { useUserData } from "@/contexts/SubmitRequest";
import NoP from "./assets/no-p.png";
import { avatarImg } from "@/utils/avatars";

const Players = ({
  gameInfo,
  showRoundResult,
}: {
  gameInfo: GameInfo;
  showRoundResult: boolean;
}) => {
  const { userData } = useUserData();
  const allBet = useMemo(() => {
    return gameInfo.betHistorys.reduce((acc, item) => {
      return accAdd(acc, item.betAmount);
    }, "0");
  }, [gameInfo]);

  return (
    <Box
      sx={{
        minHeight: "200px",
        flexShrink: 0,
        borderRadius: "25px",
        background: "#232523",
        padding: "20px",
        marginTop: "12px",
      }}
    >
      <Text>{gameInfo.betHistorys.length} Players</Text>
      {gameInfo.betHistorys.length == 0 && (
        <Flex
          sx={{
            marginTop: "35px",
            width: "100%",
            justifyContent: "center",
          }}
          align={"center"}
        >
          <Image
            src={NoP}
            sx={{
              width: "108px",
            }}
          ></Image>
        </Flex>
      )}
      <Flex
        align={"center"}
        flexDir={"column"}
        gap={"3px"}
        sx={{
          marginTop: "8px",
        }}
      >
        {gameInfo.betHistorys.map((player, index) => {
          const isYou = player.userId == userData.userId ? true : false;

          let opacity = 1;

          if (showRoundResult) {
            if (player.userId == gameInfo.winnerUser) {
              opacity = 1;
            } else {
              opacity = 0.5;
            }
          }

          return (
            <Flex
              key={index}
              sx={{
                height: "60px",
                borderRadius: "45px",
                background:
                  player.userId == gameInfo.winnerUser && showRoundResult
                    ? "linear-gradient(90deg, #97E181 0%, #666 100%)"
                    : "#000",
                width: "100%",
                padding: "0 36px 0 12px",
                position: "relative",
                opacity,
              }}
              align={"center"}
            >
              <Box
                sx={{
                  background: roundColors[index],
                  position: "absolute",
                  right: "0",
                  width: "30px",
                  height: "100%",
                  borderRadius: "0 45px 45px 0",
                }}
              ></Box>
              <Image
                src={avatarImg(player.userId)}
                sx={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  marginRight: "14px",
                }}
              ></Image>
              <Box
                sx={{
                  fontSize: "15px",
                }}
              >
                {isYou ? (
                  <Text
                    sx={{
                      color: "#4C7040",
                    }}
                  >
                    You
                  </Text>
                ) : (
                  <Text>
                    {player.firstName ?? ""} {player.lastName ?? ""}
                  </Text>
                )}
                <Text
                  sx={{
                    marginTop: "4px",
                  }}
                >
                  +{player.rewardScore} PEPE
                </Text>
              </Box>
              <Box
                sx={{
                  marginLeft: "auto",
                  fontSize: "15px",
                  textAlign: "right",
                }}
              >
                <Text sx={{}}>
                  {toFixed(accMul(accDiv(player.betAmount, allBet), 100), 2)}%
                </Text>
                <Flex
                  sx={{
                    marginTop: "4px",
                  }}
                  justify={"flex-end"}
                >
                  <Image
                    src={TonIcon}
                    sx={{
                      width: "18px",
                      marginRight: "4px",
                    }}
                  ></Image>
                  <Text>{player.betAmount}</Text>
                </Flex>
              </Box>
            </Flex>
          );
        })}
      </Flex>
    </Box>
  );
};

export default Players;
