import { Box, Flex, Image, Text, keyframes } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useUserData } from "@/contexts/SubmitRequest";
import dayjs from "dayjs";
import Lottie from "lottie-react";
import YearJson from "@/assets/23_party.json";

const animationKeyframes = keyframes`
   0% {
        transform: translate(0);
    }
    10% {
        transform: translateY(5px) scale(1.5) scaleY(0.8);
    }
    30% {
        transform: translateY(-13px) scale(1) scaleY(1) rotate(5deg);
    }
    50% {
        transform: translateY(0) scale(1) rotate(0);
    }
    55% {
        transform: translateY(0) scaleX(1.1) scaleY(0.9) rotate(0);
    }
    70% {
        transform: translateY(-4px) scaleX(1) scaleY(1) rotate(0);
    }
    80% {
        transform: translateY(0) scaleX(1) scaleY(1) rotate(0);
    }
    85% {
        transform: translateY(0) scaleX(1.05) scaleY(0.95) rotate(0);
    }
    to {
        transform: translateY(0) scaleX(1) scaleY(1);
    }

 
`;

const Time = ({ onStepChange }: { onStepChange: () => void }) => {
  const { userData } = useUserData();
  const [years, setYears] = React.useState(0);
  useEffect(() => {
    if (!userData) return;
    const registerTime = userData.registerTime * 1000;
    const nowTime = dayjs().unix() * 1000;

    const date1 = dayjs(registerTime);
    const date2 = dayjs(nowTime);
    const yearDiff = date2.diff(date1, "year");
    setYears(yearDiff);
  }, [userData]);

  return (
    <Flex
      sx={{
        height: "100%",
        padding: "3vh 0 24px",
        color: "#fff",
      }}
      justify={"space-between"}
      align={"center"}
      flexDir={"column"}
    >
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Text sx={{ fontSize: "35px", fontStyle: "normal", fontWeight: 700 }}>
          Rising star
        </Text>
        <Text sx={{ fontSize: "20px", fontStyle: "normal", marginTop: "10px" }}>
          You’ve joined Telegram{" "}
        </Text>
      </Box>

      <Box
        sx={{
          textAlign: "center",
          lineHeight: 1,
        }}
      >
        <Flex
          sx={{
            width: "280px",
            height: "280px",
          }}
          animation={`${animationKeyframes} 1s 1`}
          align={"center"}
          justify={"center"}
        >
          <Lottie
            style={{
              width: "80%",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%,-50%)",
            }}
            loop={false}
            animationData={YearJson}
          />
          <Text
            sx={{
              color: "#97E181",
              fontSize: "200px",
              fontWeight: 700,
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%,-50%)",
              zIndex: 10,
            }}
          >
            {years}
          </Text>
        </Flex>

        <Text
          sx={{
            fontSize: "30px",
            fontStyle: "normal",
            fontWeight: 700,
            marginTop: "10%",
          }}
        >
          Years age
        </Text>
      </Box>

      <Flex
        onClick={() => {
          onStepChange();
        }}
        sx={{
          width: "326px",
          height: "52px",
          padding: "6px 26px",
          background: "#97E181",
          color: "#000",
          borderRadius: "17px",
          margin: "0 auto",
          fontSize: "20px",
          fontWeight: "bold",
          cursor: "pointer",
        }}
        align={"center"}
        justify={"center"}
      >
        Continue
      </Flex>
    </Flex>
  );
};

export default Time;
