import { useContext, useEffect, useRef } from "react";
import {
  useIsConnectionRestored,
  useTonConnectUI,
  useTonWallet,
} from "@tonconnect/ui-react";
import { randomBytes, sign } from "tweetnacl";
import { Address, Cell, contractAddress, loadStateInit } from "@ton/core";
import { connectionWallet } from "@/api";
import { useUserData } from "@/contexts/SubmitRequest";

const payloadTTLMS = 1000 * 60 * 20;

function isValid(proof: any, account: any) {
  const payload = {
    address: account.address,
    public_key: account.publicKey,
    proof: {
      ...proof,
      state_init: account.walletStateInit,
    },
  };
  const stateInit = loadStateInit(
    Cell.fromBase64(payload.proof.state_init).beginParse()
  );

  return payload;
}

export function useBackendAuth() {
  const isConnectionRestored = useIsConnectionRestored();
  const wallet = useTonWallet();
  const [tonConnectUI] = useTonConnectUI();
  const interval = useRef<ReturnType<typeof setInterval> | undefined>();
  const { handleGetSuccessTask, handleGetConnectWalletCount } = useUserData();

  useEffect(() => {
    if (!isConnectionRestored) {
      return;
    }
    clearInterval(interval.current);
    if (!wallet) {
      const refreshPayload = async () => {
        tonConnectUI.setConnectRequestParameters({ state: "loading" });
        tonConnectUI.setConnectRequestParameters({
          state: "ready",
          value: {
            tonProof: Buffer.from(randomBytes(32)).toString("hex"),
          },
        });
      };
      refreshPayload();
      interval.current = setInterval(refreshPayload, payloadTTLMS);
      return;
    }

    if (
      wallet.connectItems?.tonProof &&
      !("error" in wallet.connectItems.tonProof)
    ) {
      const req = isValid(wallet.connectItems.tonProof.proof, wallet.account);
      connectionWallet(req)
        .then((res) => {
          handleGetSuccessTask();
          handleGetConnectWalletCount();
          // console.log(res, "res");
        })
        .catch((err) => {
          handleGetSuccessTask();
          handleGetConnectWalletCount();

          console.log(err, "err");
        });
    }
  }, [wallet, isConnectionRestored]);

  return {};
}
