import { checkTx } from "@/api/game";
import config from "@/constants/tgConfig";

import { Cell, Address, beginCell, storeMessage, TonClient } from "@ton/ton";

export async function retry<T>(
  fn: () => Promise<T>,
  options: { retries: number; delay: number }
): Promise<T> {
  let lastError: Error | undefined;
  for (let i = 0; i < options.retries; i++) {
    try {
      return await fn();
    } catch (e) {
      if (e instanceof Error) {
        lastError = e;
      }
      await new Promise((resolve) => setTimeout(resolve, options.delay));
    }
  }
  throw lastError;
}

export const getCheckTx = async (hash: string) => {
  return retry(
    async () => {
      const res = await checkTx(hash);
      if (res.code === 200) {
        return res.data;
      }

      throw new Error("Transaction not found");
    },
    {
      retries: 30,
      delay: 1000,
    }
  );
};

const client: any = new TonClient({
  endpoint: config.endpoint,
  // apiKey: config.apiKey,
});

export async function getTxByBOC(
  exBoc: string,
  address: string
): Promise<string> {
  const myAddress = Address.parse(address); // Address to fetch transactions from

  console.log("myAddress", myAddress.toString());
  console.log("exBoc", exBoc);

  return retry(
    async () => {
      const transactions = await client.getTransactions(myAddress, {
        limit: 1,
        archival: true,
      });
      for (const tx of transactions) {
        const inMsg = tx.inMessage;
        if (inMsg?.info.type === "external-in") {
          const inBOC = inMsg?.body;
          if (typeof inBOC === "undefined") {
            Promise.reject(new Error("Invalid external"));
            continue;
          }

          const extHash = Cell.fromBase64(exBoc).hash().toString("hex");
          const inHash = beginCell()
            .store(storeMessage(inMsg))
            .endCell()
            .hash()
            .toString("hex");

          const outMsg = tx.outMessages;

          console.log(" hash BOC", extHash);
          console.log("inMsg hash", inHash);
          console.log("checking the tx", tx, tx.hash().toString("hex"));

          // Assuming `inBOC.hash()` is synchronous and returns a hash object with a `toString` method
          if (extHash === inHash) {
            console.log(tx, "tx");
            console.log("Tx match");
            const txHash = tx.hash().toString("hex");
            console.log(`Transaction Hash: ${txHash}`);
            console.log(`Transaction LT: ${tx.lt}`);

            for (const outMessage of outMsg.values()) {
              const outMessageCell = beginCell()
                .store(storeMessage(outMessage))
                .endCell();
              const outMessageHash = outMessageCell.hash();
              const outMessageHashBase64 = outMessageHash.toString("base64");
              const hash = outMessageHashBase64
                .replace(/\+/g, "-")
                .replace(/\//g, "_")
                .replace(/=/g, ".");

              console.log("outMessageHash", outMessageHash.toString("base64"));
              console.log(hash, "hash");
              return hash;
            }
          }
        }
      }
      throw new Error("Transaction not found");
    },
    { retries: 30, delay: 2000 }
  );
}
