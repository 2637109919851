import { api } from ".";

export const getTonPrice = async () => {
  const response = await api.get(
    "https://jetton-index.tonscan.org/market_data/chart?days=1&vs_currency=USD&precision=4"
  );
  return response.data;
};

export const checkTx = async (hash: string) => {
  const token = sessionStorage.getItem("jwtToken");

  const response = await api.get("/Charge/CheckTransactionHash/" + hash, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const getInviteUserReward = async () => {
  const token = sessionStorage.getItem("jwtToken");

  const response = await api.get("/RingGame/InviteUserRewardScore/500/1", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const getGameHistory = async (
  pageIndex: number,
  status: number,
  onlyYour: boolean
) => {
  const token = sessionStorage.getItem("jwtToken");
  const url = status
    ? `/RingGame/History/${pageIndex}/${status}?onlySelf=${onlyYour}`
    : `/RingGame/History/${pageIndex}?onlySelf=${onlyYour}`;
  const response = await api.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getBetRingGame = async ({
  amount,
  count,
}: {
  amount: string;
  count: number;
}) => {
  const token = sessionStorage.getItem("jwtToken");
  const response = await api.get(
    `/RingGame/BetRingGame?amount=${amount}&betNumber=${count}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const getGameIngo = async (roundId?: number) => {
  const url = roundId
    ? `/RingGame/RoundHistory/${roundId}`
    : `/RingGame/RoundHistory/`;
  const token = sessionStorage.getItem("jwtToken");
  const response = await api.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const testBet = async ({
  amount,
  userId,
  count,
}: {
  count: number;
  amount: string;
  userId?: string;
}) => {
  const token = sessionStorage.getItem("jwtToken");
  const url = userId
    ? `/RingGame/Bet/${amount}/${userId}?betNumber=${count}`
    : `/RingGame/Bet/${amount}?betNumber=${count}`;
  const response = await api.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
