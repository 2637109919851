import { Flex, Image, Text, useDisclosure } from "@chakra-ui/react";
import HelpIcon from "./assets/help.png";
import ReferralDrawer from "./ReferralDrawer";
import HelpDrawer from "./HelpDrawer";
import { useTonAddress } from "@tonconnect/ui-react";
import ConnectWallet from "../Button/ConnectWallet";

const Header = ({ balance }: { balance: string }) => {
  const rawAddress = useTonAddress(true);
  const {
    isOpen: isHelpOpen,
    onOpen: onHelpOpen,
    onClose: onHelpClose,
  } = useDisclosure();
  const {
    isOpen: isReferralOpen,
    onOpen: onReferralOpen,
    onClose: onReferralClose,
  } = useDisclosure();

  return (
    <Flex>
      <Flex
        sx={{
          marginLeft: "auto",
        }}
        gap={"6px"}
      >
        <Flex
          align={"center"}
          justify={"center"}
          sx={{
            width: "63px",
            height: "33px",
            borderRadius: "10px",
            border: "1px solid  #4C4C4C",
          }}
          onClick={() => {
            onHelpOpen();
          }}
        >
          <Image
            src={HelpIcon}
            sx={{
              marginRight: "2px",
              width: "14px",
            }}
          ></Image>
          <Text
            sx={{
              fontSize: "15px",
              fontStyle: "normal",
              fontWeight: 500,
            }}
          >
            Help
          </Text>
        </Flex>
        {rawAddress && (
          <Flex
            align={"center"}
            justify={"center"}
            sx={{
              width: "63px",
              height: "33px",
              borderRadius: "10px",
              border: "1px solid  #97E181",
            }}
            onClick={() => {
              onReferralOpen();
            }}
          >
            <Text
              sx={{
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: 500,
              }}
            >
              Invite
            </Text>
          </Flex>
        )}
        <ConnectWallet balance={balance}></ConnectWallet>
      </Flex>

      <ReferralDrawer
        isOpen={isReferralOpen}
        onClose={onReferralClose}
      ></ReferralDrawer>
      <HelpDrawer isOpen={isHelpOpen} onClose={onHelpClose}></HelpDrawer>
    </Flex>
  );
};
export default Header;
