import { Box, Text, Image, Flex } from "@chakra-ui/react";
import ArrowIcon from "./assets/arrow.png";

const navList = [
  {
    value: 0,
    name: "All",
  },
  {
    value: 4,
    name: "Completed",
  },
  {
    value: 5,
    name: "Canceled",
  },
];

const HistoryNav = ({
  active,
  onActiveChange,
}: {
  active: number;
  onActiveChange: (index: number, status: number) => void;
}) => {
  return (
    <Flex sx={{}} gap={"8px"}>
      {navList.map((item, index) => {
        return (
          <Flex
            sx={{
              flex: 1,
              border: "1px solid  #97E181",
              height: "37px",
              borderRadius: "10px",
              color: active === index ? "#000" : "#fff",
              background: active === index ? "#97E181" : "#4C4C4C",
              fontSize: "15px",
            }}
            key={index}
            align={"center"}
            justify={"center"}
            onClick={() => {
              onActiveChange(index, item.value);
            }}
          >
            {item.name}
          </Flex>
        );
      })}
    </Flex>
  );
};

export default HistoryNav;
