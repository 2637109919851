import {
  Flex,
  Modal,
  ModalContent,
  Text,
  Box,
  Image,
  ModalOverlay,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useEffect, useMemo } from "react";
import CloseIcon from "@/assets/close.svg";
import { startTime } from "@/pages/Home";
import CheckedBg from "@/assets/checked-bg.png";
import TodayBg from "@/assets/today-bg.png";
import PastBg from "@/assets/past-bg.png";
import BLogoIcon from "@/assets/logo.png";
import FutureBg from "@/assets/future-bg.png";
import toast from "react-hot-toast";
import PartAniation from "@/assets/20_party.json";
import Lottie from "lottie-react";
dayjs.extend(utc);

const DayItem = ({
  signed,
  day,
  value,
  reward,
}: {
  signed: boolean;
  day: number;
  reward: number;
  value: number;
}) => {
  const status = useMemo(() => {
    const now = dayjs().utc();
    const diff = now.diff(startTime, "day");

    if (signed) {
      return "signed";
    }
    if (value < diff) {
      return "past";
    }

    if (value > diff) {
      return "future";
    }

    return "default";
  }, [signed]);

  return (
    <Box
      sx={{
        width: "77.5px",
        height: "79px",
        overflow: "hidden",
        position: "relative",
        cursor: status === "default" ? "pointer" : "not-allowed",
        background:
          status == "signed"
            ? `url(${CheckedBg}) no-repeat`
            : status == "future"
            ? `url(${FutureBg}) no-repeat`
            : status == "past"
            ? `url(${PastBg}) no-repeat`
            : `url(${TodayBg}) no-repeat`,
        backgroundSize: "100%",
      }}
    >
      <Flex
        sx={{
          height: "100%",
          position: "relative",
        }}
        flexDir={"column"}
        align={"center"}
        justify={"center"}
      >
        <Image
          src={BLogoIcon}
          sx={{
            width: "26px",
          }}
        ></Image>
        <Text
          sx={{
            color: "#000",
            fontFamily: "Avenir",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 900,
          }}
        >
          {reward}
        </Text>
        <Text
          sx={{
            color: "#000",
            fontSize: "10px",
            fontWeight: "bold",
            textAlign: "center",
            fontFamily: "Avenir",
          }}
        >
          DAY {day}
        </Text>
      </Flex>
    </Box>
  );
};

const list = [
  {
    day: 1,
    value: 0,
    reward: 500,
  },
  {
    day: 2,
    value: 1,
    reward: 1000,
  },
  {
    day: 3,
    value: 2,
    reward: 1500,
  },
  {
    day: 4,
    value: 3,

    reward: 2000,
  },
  {
    day: 5,
    value: 4,

    reward: 2500,
  },
  {
    day: 6,
    value: 5,
    reward: 3000,
  },
  {
    day: 7,
    value: 6,
    reward: 5000,
  },
];

const SignInModal = ({
  loginAward,
  onClose,
  isOpen,
  handleLoginReward,
}: {
  loginAward: any;
  isOpen: boolean;
  onClose: () => void;
  handleLoginReward: (unixTime?: number) => Promise<boolean>;
}) => {
  const now = dayjs().utc();
  const diff = now.diff(startTime, "day");
  const handleAward = async () => {
    const now = dayjs().utc();

    if (now.valueOf() < startTime.valueOf()) {
      return;
    }

    await handleLoginReward();
    toast(
      <Flex
        sx={{
          background: "#97E181",
          fontSize: "20px",
          fontWeight: 500,
          fontFamily: "Avenir",
        }}
        align={"center"}
        gap={"22px"}
      >
        <Lottie
          style={{
            width: "66px",
          }}
          animationData={PartAniation}
          loop={true}
        />
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <Text>Check in successfully</Text>
          <Text>+ {list[diff].reward}PEPES</Text>
        </Box>
      </Flex>,
      {
        duration: 4000,
        position: "bottom-center",
        style: {
          background: "#97E181",
          borderRadius: "20px",
          padding: "0",
          width: "100%",
        },
      }
    );
  };

  const todaySigned = useMemo(() => {
    return !!loginAward[diff];
  }, [loginAward]);

  const allReward = useMemo(() => {
    const key = Object.keys(loginAward);
    const total = key.reduce((prev, cur) => {
      return prev + Number(loginAward[cur]);
    }, 0);

    return total;
  }, [loginAward]);

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered autoFocus={false}>
      <ModalOverlay
        sx={{
          background: "rgba(217, 217, 217, 0.15)",
          backdropFilter: "blur(5px)",
        }}
      />
      <ModalContent
        sx={{
          background: "#97E181",
          width: "332px",
          border: "none",
          borderImage: "none",
          boxShadow: "none",
          padding: "10px 0 0",
          borderRadius: "21px",
        }}
      >
        {" "}
        <Image
          src={CloseIcon}
          sx={{
            position: "absolute",
            top: "14px",
            right: "10px",
            cursor: "pointer",
          }}
          onClick={onClose}
        ></Image>
        <Flex flexDir={"column"} align={"center"}>
          <Text
            sx={{
              color: "#000",
              fontSize: "20px",
              fontFamily: "Avenir",
              fontWeight: 900,
            }}
          >
            7-Day Check-in Event
          </Text>

          <Box
            sx={{
              width: "100%",
              padding: "0 10px",
              marginTop: "2px",
            }}
          >
            <Flex
              sx={{
                height: "30px",
                fontSize: "11px",
                width: "100%",
                background: "#fff",
                color: "#4C4C4C",
                borderRadius: "26px",
              }}
              align={"center"}
              justify={"center"}
            >
              <Text>
                {" "}
                Consistent Check-ins to get{" "}
                <span
                  style={{
                    color: "#97E181",
                  }}
                >
                  {" "}
                  double{" "}
                </span>{" "}
                $PEPES points!{" "}
              </Text>
            </Flex>
          </Box>

          <Box
            sx={{
              background: "#232523",
              borderRadius: "20px",
              padding: "25px 0 10px",
              marginTop: "8px",
            }}
          >
            {" "}
            <Flex
              sx={{
                flexWrap: "wrap",
                justifyContent: "center",
                gap: "0 2px",
              }}
            >
              {list.map((item, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      marginTop: index > 3 && "-16px",
                    }}
                  >
                    <DayItem
                      signed={loginAward && loginAward[index]}
                      day={item.day}
                      value={item.value}
                      reward={item.reward}
                    ></DayItem>
                  </Box>
                );
              })}
            </Flex>
            <Box
              sx={{
                padding: "0 15px",
              }}
            >
              <Flex
                align={"center"}
                justify={"center"}
                sx={{
                  height: "35px",
                  width: "100%",
                  background:
                    todaySigned || diff >= 7
                      ? "#4C4C4C"
                      : "linear-gradient(90deg, #97E181 0%, #FFF 100%)",
                  borderRadius: "10px",
                  marginTop: "28px",
                  color: todaySigned || diff >= 7 ? "#7C7C7C" : "#000",
                  fontWeight: "bold",
                  cursor: todaySigned || diff >= 7 ? "not-allowed" : "pointer",
                }}
                onClick={() => {
                  if (!todaySigned && diff < 7) {
                    handleAward();
                  }
                }}
              >
                {todaySigned ? "Signed-in" : "Check-in"}
              </Flex>
            </Box>
          </Box>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default SignInModal;
