import MemeTownBg from "./assets/memeBg.png";
import MemeTownLogo from "./assets/memeLogo1.png";
import PacketBg from "./assets/packetBg.png";
import PacketLogo from "./assets/packetLogo.png";

import MetaBg from "./assets/metaBg.png";
import MetaLogo from "./assets/metaLogo.png";

import CatchTonBg from "./assets/catchTonBg.png";
import CatchTonLogo from "./assets/catchTonLogo.png";

import GoatsBg from "./assets/goatsBg.png";
import GoatsLogo from "./assets/goatsLogo.png";

import DejenDogBg from "./assets/dejenDogBg.png";
import DejenDogLogo from "./assets/dejenDogLogo.svg";

import BtokBg from "./assets/btokBg.png";
import BtokLogo from "./assets/btokLogo.png";

import AkefishBg from "./assets/akefishBg.png";
import AkefishLogo from "./assets/akefishLogo.png";

import TelluckBg from "./assets/telluckBg.png";
import TelluckLogo from "./assets/telluckLogo.png";

import TreasureMapBg from "./assets/treasureMapBg.png";
import TreasureMapLogo from "./assets/treasureMapLogo.png";

import YuligoBg from "./assets/yuligoBg.png";
import YuligoLogo from "./assets/yuligoLogo.png";

import MonsterkingdomBg from "./assets/monsterkingdomBg.png";
import MonsterkingdomLogo from "./assets/monsterkingdomLogo.png";

import BeeverseBg from "./assets/beeverseBg.png";
import BeeverseLogo from "./assets/beeverseLogo.png";

export const yuligoList = [
  {
    btnText: "Go",
    title: "Join YuliGo",
    amount: 500,
    url: "https://t.me/YuliGoBot/app?startapp=74R6U3",
    changeType: 45,
    des: "",
    type: 0,
  },
];

export const monsterKingdomList = [
  {
    btnText: "Go",
    title: "Join Monsterkingdom",
    amount: 500,
    url: "https://t.me/Monsterkingdom_bot/Monsterkingdom?startapp=eyJzaGFyZUlkIjoxOTc4ODg4OTMxfQ==",
    changeType: 46,
    des: "",
    type: 0,
  },
];

export const beeverseList = [
  {
    btnText: "Go",
    title: "Join Beeverse Bot",
    amount: 500,
    url: "https://t.me/bee_verse_bot",
    changeType: 47,
    des: "",
    type: 0,
  },
];

export const btokList = [
  {
    btnText: "Join",
    title: "Play BAYCMiner",
    amount: 500,
    url: "https://t.me/BtokMinerBot",
    task: 28,
    changeType: 28,
    des: "",
    type: 0,
  },
  {
    btnText: "Join",
    title: "Join BAYCMiner",
    amount: 500,
    url: "https://t.me/bayc_miner",
    task: 29,
    changeType: 29,
    des: "",
    type: 0,
  },
];

export const memeTownList = [
  {
    btnText: "Go",
    title: "Join Channel",
    amount: 500,
    url: "https://t.me/MemeTownNews",
    changeType: 26,
    des: "",
    type: 0,
  },
  {
    btnText: "Join",
    title: "Join Bot",
    amount: 500,
    url: "https://t.me/MemeTownBot/app?startapp=ref_BiXhWs",
    changeType: 27,
    des: "",
    type: 0,
  },
];

export const packetGiftList = [
  {
    btnText: "Go",
    title: "Earn Money",
    amount: 500,
    url: "https://t.me/packetgift_bot/hongbao?startapp=JisRi1uZhq1P26frXf8u",
    changeType: 24,
    des: "",
    type: 0,
  },
  {
    btnText: "Join",
    title: "Join Channel",
    amount: 500,
    url: "https://t.me/packetgift_group",
    changeType: 25,
    des: "",
    type: 0,
  },
];

export const metaList = [
  {
    btnText: "Go",
    title: "Play Game to Earn",
    amount: 500,
    url: "https://t.me/realityrush_bot/play?startapp=refererID1978888931",
    changeType: 21,
    des: "",
    type: 0,
  },
  {
    btnText: "Join",
    title: "Join TG",
    amount: 500,
    url: "https://t.me/RealityMeta",
    changeType: 22,
    des: "",
    type: 0,
  },
];

export const catchTonList = [
  {
    btnText: "Follow",
    title: "Follow CATCH TON X",
    amount: 500,
    url: "https://x.com/CatchTON_app",
    changeType: 18,
    des: "",
    type: 1,
  },
  {
    btnText: "Join",
    title: "Join CATCH TON",
    amount: 500,
    url: "https://t.me/catchton_channel",
    changeType: 19,
    des: "",
    type: 0,
  },
  {
    btnText: "Go",
    title: "Play CATCH TON",
    amount: 500,
    url: "https://t.me/catchton_bot/run?startapp=7d908008-f345-4b46-8c56-e865c1ba2bf7",
    changeType: 20,
    des: "",
    type: 0,
  },
];

export const potusList = [
  {
    btnText: "Play",
    title: "Play POTUS Click",
    amount: 500,
    url: "https://t.me/potusclick_bot/app?startapp=r1978888931",
    task: 17,
    changeType: 17,
    des: "",
    type: 0,
  },
];

export const goatsList = [
  {
    btnText: "Follow",
    title: "Follow GOATS X",
    amount: 500,
    url: "https://x.com/realGOATS_TG",
    changeType: 14,
    des: "",
    type: 1,
  },
  {
    btnText: "Join",
    title: "Join GOATS Channel",
    amount: 500,
    url: "https://t.me/realgoats_channel",
    changeType: 15,
    des: "",
    type: 0,
  },
  {
    btnText: "Go",
    title: "Get GOATS",
    amount: 500,
    url: "https://t.me/realgoats_bot/run?startapp=cc7d8adf-1e48-4695-8e96-81d330666806",
    changeType: 16,
    des: "",
    type: 0,
  },
];

export const qstartList = [
  {
    btnText: "Follow",
    title: "Follow",
    amount: 500,
    url: "https://x.com/StarProtocol_HQ",
    changeType: 12,
    des: "@StarProtocol_HQ on X",
    type: 1,
  },
  {
    btnText: "Join",
    title: "Join WL Giveaway Event",
    amount: 500,
    url: "https://x.com/TonPepes/status/1817821547752763768",
    changeType: 13,
    des: "(worth 200USDT)",
    type: 1,
  },
];

export const dejenDogList = [
  {
    btnText: "Follow",
    title: "Follow @DejenDogBot on X ",
    amount: 500,
    url: "https://x.com/DejenDogBot",
    changeType: 9,
    type: 1,
  },
  {
    btnText: "Play",
    title: "Play DejenDog",
    amount: 500,
    url: "https://t.me/DejenDogBot?start=PepesTon",
    changeType: 10,
    type: 0,
  },
  {
    btnText: "Join",
    title: "Join DejenDog",
    amount: 500,
    url: "https://t.me/DejenNews",
    changeType: 11,
    type: 0,
  },
];

const akefishList = [
  {
    btnText: "Go",
    title: "Join AKEFish",
    amount: 500,
    url: "https://t.me/AKEFishBot?start=in1129721038",
    changeType: 38,
    type: 0,
  },
];

const telluckList = [
  {
    btnText: "Go",
    title: "Join TelLucky",
    amount: 500,
    url: "https://t.me/TelLuckyBot/MiniApp?startapp=GCG7KPHC",
    changeType: 39,
    type: 0,
  },
];

const treasureMapList = [
  {
    btnText: "Go",
    title: "Join Treasure map",
    amount: 500,
    url: "https://t.me/TreasureMapAppBot/miniapp?startapp=9nLL8iCxLz7Xc8DyH2",
    changeType: 40,
    type: 0,
  },
];

export const partner = [
  {
    bg: YuligoBg,
    title: "YuliGo",
    logo: YuligoLogo,
    list: yuligoList,
  },
  {
    bg: MonsterkingdomBg,
    title: "Monsterkingdom",
    logo: MonsterkingdomLogo,
    list: monsterKingdomList,
  },
  {
    bg: BeeverseBg,
    title: "Beeverse Bot",
    logo: BeeverseLogo,
    list: beeverseList,
  },
  {
    bg: AkefishBg,
    title: "AKEFish",
    logo: AkefishLogo,
    list: akefishList,
  },
  {
    bg: TelluckBg,
    title: "TelLucky",
    logo: TelluckLogo,
    list: telluckList,
  },
  {
    bg: TreasureMapBg,
    title: "Treasure map",
    logo: TreasureMapLogo,
    list: treasureMapList,
  },
  {
    bg: BtokBg,
    title: "Btok",
    logo: BtokLogo,
    list: btokList,
  },
  {
    bg: MemeTownBg,
    title: "MEMETOWN",
    logo: MemeTownLogo,
    list: memeTownList,
  },
  {
    bg: PacketBg,
    title: "Packet Gift",
    logo: PacketLogo,
    list: packetGiftList,
  },
  {
    bg: MetaBg,
    title: "Reality Meta",
    logo: MetaLogo,
    list: metaList,
  },
  {
    bg: CatchTonBg,
    title: "Catch Ton",
    logo: CatchTonLogo,
    list: catchTonList,
  },
  {
    bg: GoatsBg,
    title: "GOATS",
    logo: GoatsLogo,
    list: goatsList,
  },
  {
    bg: DejenDogBg,
    title: "DejenDog",
    logo: DejenDogLogo,
    list: dejenDogList,
  },
];

export default partner;
