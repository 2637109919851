import { Box, Flex, Image, Text } from "@chakra-ui/react";
import TonIcon from "./assets/tonicon.png";
import { ReactComponent as PLIcon } from "./assets/pl.svg";
import { ReactComponent as PLLIcon } from "./assets/pll.svg";
import { ReactComponent as PRIcon } from "./assets/pr.svg";
import { ReactComponent as PRRIcon } from "./assets/prr.svg";
import OtherAvatarIcon from "@/assets/other-avatar.png";
import CancelIcon from "./assets/cancel.png";
import { avatarImg } from "@/utils/avatars";
export const Page = ({
  currentPage,
  allPage,
  onPage,
}: {
  currentPage: number;
  allPage: number;
  onPage: (page: number) => void;
}) => {
  return (
    <Flex
      sx={{
        marginTop: "24px",
        "&> .bt": {
          borderRadius: "10px",
          border: "1px solid  #4C4C4C",
          background: "#232523",
          width: "31px",
          height: "28px",
          alignItems: "center",
          justifyContent: "center",
        },
      }}
      align={"center"}
      justify={"center"}
      gap={"8px"}
    >
      <Flex
        className="bt"
        onClick={() => {
          currentPage > 1 && onPage(1);
        }}
      >
        <PLLIcon
          style={{
            color: currentPage > 1 ? "#fff" : "#4C4C4C",
          }}
        ></PLLIcon>
      </Flex>
      <Flex
        className="bt"
        onClick={() => {
          currentPage > 1 && onPage(currentPage - 1);
        }}
      >
        <PLIcon
          style={{
            color: currentPage > 1 ? "#fff" : "#4C4C4C",
            width: "8px",
          }}
        ></PLIcon>
      </Flex>
      <Box
        sx={{
          color: "#A1A1A1",
          fontFamily: "Avenir",
          fontSize: "13px",
        }}
      >
        Page {currentPage} of {allPage}
      </Box>
      <Flex
        className="bt"
        onClick={() => {
          currentPage < allPage && onPage(currentPage + 1);
        }}
      >
        <PRIcon
          style={{
            color: currentPage < allPage ? "#fff" : "#4C4C4C",
            width: "8px",
          }}
        ></PRIcon>
      </Flex>
      <Flex
        className="bt"
        onClick={() => {
          currentPage < allPage && onPage(allPage);
        }}
      >
        <PRRIcon
          style={{
            color: currentPage < allPage ? "#fff" : "#4C4C4C",
          }}
        ></PRRIcon>
      </Flex>
    </Flex>
  );
};

const RoundList = ({ list }: { list: any }) => {
  return (
    <Box>
      <Box
        sx={{
          minHeight: "300px",
          background: "#232523",
          borderRadius: "25px",
          marginTop: "12px",
          padding: "0 10px",
        }}
      >
        <Flex
          sx={{
            height: "42px",
            fontSize: "13px",
          }}
        >
          <Flex
            sx={{
              width: "80px",
            }}
            align={"center"}
            justify={"center"}
          >
            Round
          </Flex>
          <Flex
            sx={{
              flex: 1,
            }}
            align={"center"}
          >
            Winner
          </Flex>
          <Flex
            sx={{
              textAlign: "right",
              width: "64px",
            }}
            align={"center"}
          >
            Prize pool
          </Flex>
        </Flex>
        <Box>
          {list.map((item: any, index: number) => {
            console.log(item, "item");
            const winer = item.betHistorys.find((fitem: any) => {
              return fitem.userId == item.winnerUser;
            });
            return (
              <Flex
                key={index}
                sx={{
                  height: "50px",
                  borderTop: "1px solid #4C4C4C",
                }}
              >
                <Flex
                  sx={{
                    width: "80px",
                    color: "#A1A1A1",
                    fontSize: "15px",
                    fontWeight: 500,
                  }}
                  align={"center"}
                  justify={"center"}
                >
                  {item.id}
                </Flex>
                <Flex
                  sx={{
                    flex: 1,
                  }}
                  align={"center"}
                >
                  {item.status == 5 && (
                    <>
                      <Image
                        src={CancelIcon}
                        sx={{
                          width: "40px",
                          height: "40px",
                          marginRight: "8px",
                        }}
                      ></Image>{" "}
                      <Text
                        sx={{
                          fontSize: "15px",
                          fontStyle: "normal",
                          fontWeight: 900,
                        }}
                      >
                        Cancel
                      </Text>
                    </>
                  )}

                  {(item.status == 2 ||
                    item.status == 3 ||
                    item.status == 4) && (
                    <>
                      <Image
                        src={avatarImg(item.winnerUser)}
                        sx={{
                          width: "40px",
                          height: "40px",
                          marginRight: "8px",
                        }}
                      ></Image>
                      <Text
                        sx={{
                          fontSize: "15px",
                          fontStyle: "normal",
                          fontWeight: 900,
                        }}
                      >
                        {winer?.firstName ?? ""} {winer?.lastName ?? ""}
                      </Text>
                    </>
                  )}
                </Flex>
                <Flex
                  sx={{
                    width: "64px",
                  }}
                  justify={"flex-end"}
                >
                  {(item.status == 2 ||
                    item.status == 3 ||
                    item.status == 4) && (
                    <Flex align={"center"} justify={"flex-end"}>
                      <Image
                        src={TonIcon}
                        sx={{
                          width: "17px",
                          height: "17px",
                          marginRight: "4px",
                        }}
                      ></Image>
                      <Text
                        sx={{
                          fontSize: "15px",
                          fontStyle: "normal",
                          fontWeight: 900,
                        }}
                      >
                        {item.totalBetAmount}
                      </Text>
                    </Flex>
                  )}
                </Flex>
              </Flex>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default RoundList;
