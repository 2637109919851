import { Box, Text, Flex, Image, Fade } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import OtherAvatarIcon from "@/assets/other-avatar.png";
import { useUserData } from "@/contexts/SubmitRequest";
import { avatarImg } from "@/utils/avatars";
import TopNav from "@/components/Leaderboard/TopNav";
import dayjs from "dayjs";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const rewardAmount = (rank: number) => {
  if (rank === 1) {
    return "3 Ton";
  } else if (rank === 2) {
    return "2 Ton";
  } else if (rank === 3) {
    return "1 Ton";
  } else if (rank > 3 && rank <= 20) {
    return "0.5 Ton";
  }
  return "0 Ton";
};

export const RankItem = ({
  isMe = false,
  detail,
  bgColor = "transparent",
  color = "#fff",
  rank,
  paddingRight = "0px",
  paddingLeft = "0px",
  score,
  rewardUsdt,
}: {
  isMe?: boolean;
  detail: any;
  bgColor?: string;
  color?: string;
  rank?: string;
  paddingRight?: string;
  paddingLeft?: string;
  score?: string;
  rewardUsdt?: string;
}) => {
  return (
    <Flex
      align={"center"}
      sx={{
        borderRadius: "17px",
        background: bgColor,
        padding: "5px 16px 5px 25px",
        marginBottom: "6px",
        color: isMe ? "#000" : "#fff",
        paddingLeft: paddingLeft,
      }}
      justify={"space-between"}
    >
      <Flex align={"center"}>
        <Image
          src={avatarImg(detail?.userId)}
          sx={{
            marginRight: "16px",
            width: "40px",
            height: "40px",
          }}
        ></Image>
        <Box
          sx={{
            fontSize: "15px",
          }}
        >
          <Text
            sx={{
              color: isMe ? "#000" : "#fff",
              height: "20px",
            }}
          >
            {detail
              ? detail?.firstName ?? "" + " " + detail?.lastName ?? ""
              : "  "}
          </Text>

          <Text
            sx={{
              color: isMe ? "#4c4c4c" : "#A1A1A1",
            }}
          >
            {Number(score).toLocaleString()}{" "}
            <span
              style={{
                fontSize: "10px",
              }}
            >
              PEPES
            </span>{" "}
          </Text>
          {rewardUsdt && (
            <Text
              sx={{
                fontSize: "15px",
                fontWeight: 500,
                color: isMe ? "#fff" : "#97E181",
              }}
            >
              Reward: {rewardUsdt}
            </Text>
          )}
        </Box>
      </Flex>
      <Text
        sx={{
          fontWeight: 700,
          fontSize: "15px",
          paddingRight: paddingRight,
          color: color,
        }}
      >
        {rank}
      </Text>
    </Flex>
  );
};

const Leaderboard = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeDate, setActiveDate] = useState(0);
  const {
    handleGetLeaderboard,
    handleGetDailyReward,
    leaderboardTotal,
    leaderboardMyData,
    leaderboardList,
    dailyReward,
  } = useUserData();

  useEffect(() => {
    handleGetDailyReward();
    handleGetLeaderboard();
  }, []);

  return (
    <Box
      sx={{
        padding: "2.7778vh 30px 20px",
        height: "100%",
        overflowY: "auto",
        color: "#fff",
      }}
    >
      <Text
        sx={{
          fontSize: "25px",
          fontStyle: "normal",
          fontWeight: 900,
          textAlign: "center",
        }}
      >
        PEPES Ranking
      </Text>
      <TopNav activeTab={activeTab} setActiveTab={setActiveTab} />

      <Fade in={activeTab === 0} unmountOnExit>
        <Box>
          <Box
            sx={{
              marginTop: "3.7037vh",
              paddingBottom: "4px",
            }}
          >
            <RankItem
              isMe={true}
              bgColor="#97E181"
              color="#000"
              rank={
                leaderboardMyData.rank > 999
                  ? "#999+"
                  : "#" + leaderboardMyData.rank
              }
              paddingRight="20px"
              detail={leaderboardMyData}
              paddingLeft="22px"
              score={leaderboardMyData.score}
            ></RankItem>
          </Box>

          <Box
            sx={{
              fontSize: "17px",
              fontStyle: "normal",
              fontWeight: 900,
              marginTop: "40px",
            }}
          >
            {leaderboardTotal > 0 ? 30000 + 3 * leaderboardTotal : 0} Holders
          </Box>

          <div
            style={{
              marginTop: "20px",
            }}
          >
            {leaderboardList.map((item: any, index: number) => {
              let rank = "#" + String(index + 1);
              let color = "#fff";
              if (index === 0) {
                rank = "🥇" + rank;
                color = "#FCC40B";
              } else if (index === 1) {
                rank = "🥈" + rank;
                color = "#B1B1B1";
              } else if (index === 2) {
                rank = "🥉" + rank;
                color = "#AB5820";
              }
              return (
                <RankItem
                  color={color}
                  key={index}
                  rank={rank}
                  detail={item}
                  score={item.score}
                  rewardUsdt={""}
                ></RankItem>
              );
            })}
          </div>
        </Box>
      </Fade>

      <Fade in={activeTab === 1} unmountOnExit>
        <Box>
          <Swiper
            onSlideChange={(swiper) => setActiveDate(swiper.activeIndex)}
            initialSlide={dailyReward.length - 1}
            style={{
              marginTop: "3.7037vh",
            }}
          >
            {[...dailyReward].reverse().map((item: any, index: number) => {
              const realIndex = dailyReward.length - 1 - index;
              const myData = item.find((fItem: any) => {
                return fItem.userId === leaderboardMyData.userId;
              });
              let myRewardUsdt = "0 Ton";
              if (myData) {
                myRewardUsdt = rewardAmount(myData?.rank);
              }
              return (
                <SwiperSlide
                  key={index}
                  style={{
                    minHeight: "50vh",
                  }}
                >
                  <Box sx={{ paddingBottom: "4px" }}>
                    {myData && (
                      <RankItem
                        isMe={true}
                        bgColor="#97E181"
                        color="#000"
                        rank={myData.rank > 999 ? "#999+" : "#" + myData.rank}
                        paddingRight="20px"
                        detail={myData}
                        paddingLeft="22px"
                        score={myData.score}
                        rewardUsdt={myRewardUsdt}
                      />
                    )}
                  </Box>
                  <Box>
                    <Box
                      sx={{
                        fontSize: "17px",
                        fontStyle: "normal",
                        fontWeight: 900,
                        marginTop: "40px",
                      }}
                    >
                      {realIndex === 0 && "Today"}
                      {realIndex === 1 && "Yesterday"}
                      {realIndex === 2 && "Day before yesterday"} /{" "}
                      {dayjs()
                        .subtract(realIndex, "day")
                        .format("MMM DD, YYYY")}
                    </Box>

                    <div style={{ marginTop: "20px" }}>
                      {item.map((item: any, index: number) => {
                        let rank = "#" + String(index + 1);
                        let color = "#fff";
                        if (index === 0) {
                          rank = "🥇" + rank;
                          color = "#FCC40B";
                        } else if (index === 1) {
                          rank = "🥈" + rank;
                          color = "#B1B1B1";
                        } else if (index === 2) {
                          rank = "🥉" + rank;
                          color = "#AB5820";
                        }
                        const rewardUsdt = rewardAmount(index + 1);
                        return (
                          <RankItem
                            color={color}
                            key={index}
                            rank={rank}
                            detail={item}
                            score={item.score}
                            rewardUsdt={rewardUsdt}
                          />
                        );
                      })}
                    </div>
                  </Box>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Box>
      </Fade>
    </Box>
  );
};

export default Leaderboard;
