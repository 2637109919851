import { Box, Text, Image, Flex } from "@chakra-ui/react";
import ArrowIcon from "./assets/arrow.png";
import HistoryNav from "./HistoryNav";
import { useEffect, useState } from "react";
import ClaimWin from "./ClaimWin";
import RoundList, { Page } from "./RoundList";
import { getGameHistory } from "@/api/game";

const RoundDetail = ({
  onShowRoundHistory,
}: {
  onShowRoundHistory: () => void;
}) => {
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [onlyYour, setOnlyYour] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [list, setList] = useState([]);

  const handleActiveChange = (index: number, status: number) => {
    setActiveIndex(index);
    setStatus(status);
    setPage(1);
  };
  const handleOnlyYourChange = () => {
    setOnlyYour(!onlyYour);
  };

  const handleGetHistory = async () => {
    const res = await getGameHistory(page, status, onlyYour);
    if (res.code !== 200) {
      return;
    }
    const data = res.data;
    setDataCount(data.dataCount);
    setList(data.data);
  };

  const handlePage = (_page: number) => {
    setPage(_page);
  };

  useEffect(() => {
    handleGetHistory();
  }, [page, status, onlyYour]);

  return (
    <Box sx={{}}>
      <Box
        sx={{
          background: "#97E181",
          width: "100%",
          borderRadius: "25px",
          overflow: "hidden",
        }}
      >
        <Flex
          sx={{
            height: "48px",
            paddingLeft: "24px",
          }}
          onClick={onShowRoundHistory}
          align={"center"}
        >
          <Image
            src={ArrowIcon}
            sx={{
              width: "15px",
              marginRight: "8px",
            }}
          ></Image>
          <Text
            sx={{
              color: "#000",
              fontSize: "15px",
              fontWeight: 500,
            }}
          >
            Current Round
          </Text>
        </Flex>
        <Box
          sx={{
            background: "#232523",
            // height: "253px",
            borderRadius: "25px",
            padding: "24px 14px",
          }}
        >
          <HistoryNav
            active={activeIndex}
            onActiveChange={handleActiveChange}
          ></HistoryNav>
          <ClaimWin
            onlyYour={onlyYour}
            onOnlyYourChange={handleOnlyYourChange}
          ></ClaimWin>
        </Box>
      </Box>
      <RoundList list={list}></RoundList>
      <Page
        currentPage={page}
        allPage={Math.ceil(dataCount / 10)}
        onPage={handlePage}
      ></Page>
    </Box>
  );
};

export default RoundDetail;
