import { Box, Text, Flex, Image } from "@chakra-ui/react";
import React, { useEffect } from "react";
import LogoIcon from "@/assets/b-logo.png";
import config from "@/constants/tgConfig";
import { useInitData } from "@tma.js/sdk-react";
import { RankItem } from "./Leaderboard";
import { useUserData } from "@/contexts/SubmitRequest";

const Friends = () => {
  const initData = useInitData();
  const { friendList, friendTotal, handleGetFriend } = useUserData();
  const handleInviteFriends = () => {
    const inviteCode = initData.user.id;
    const des = "Join the PEPES journey now!";
    const share_url =
      "https://t.me/share/url?url=" +
      encodeURIComponent(`${config.MINI_APP_URL}?startapp=${inviteCode}`) +
      "&text=" +
      encodeURIComponent(des);
    window.open(share_url);
  };

  useEffect(() => {
    handleGetFriend();
  }, []);

  return (
    <Flex
      sx={{
        padding: "20px 30px  0",
        height: "100%",
        overflowY: "scroll",
        color: "#fff",
      }}
      flexDir={"column"}
      align={"center"}
    >
      {" "}
      <Image
        src={LogoIcon}
        sx={{
          width: "135px",
          height: "135px",
          marginTop: "30px",
        }}
      ></Image>
      <Text
        sx={{
          fontSize: "25px",
          fontStyle: "normal",
          fontWeight: 900,
        }}
      >
        Invite frens to get more PEPES{" "}
      </Text>
      <Flex
        onClick={() => {
          handleInviteFriends();
        }}
        sx={{
          width: "100%",
          height: "52px",
          padding: "6px 26px",
          background: "#97E181",
          color: "#000",
          borderRadius: "17px",
          marginTop: "20px",
          fontSize: "20px",
          fontWeight: "bold",
          cursor: "pointer",
        }}
        align={"center"}
        justify={"center"}
      >
        Invite Friends
      </Flex>
      <Box
        sx={{
          width: "100%",
          marginTop: "50px",
        }}
      >
        {friendTotal > 0 && (
          <Text
            sx={{
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 700,
            }}
          >
            {friendTotal} Friends
          </Text>
        )}

        <div
          style={{
            marginTop: "20px",
          }}
        >
          {friendList.map((item: any, index: number) => {
            let rank = "#" + String(index + 1);
            if (index === 0) {
              rank = "🥇" + rank;
            } else if (index === 1) {
              rank = "🥈" + rank;
            } else if (index === 2) {
              rank = "🥉" + rank;
            }
            return (
              <RankItem
                key={index}
                detail={item}
                score={item.rewardScore}
                bgColor="#232523"
                paddingLeft="20px"
              ></RankItem>
            );
          })}
        </div>
      </Box>
    </Flex>
  );
};

export default Friends;
