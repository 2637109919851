import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Image,
  Flex,
  Text,
} from "@chakra-ui/react";
import CloseIcon from "./assets/close.png";

const PlayDrawerWrap = ({
  title,
  isOpen,
  onClose,
  children,
}: {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}) => {
  return (
    <Drawer isOpen={isOpen} placement="bottom" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent
        sx={{
          padding: 0,
          background: "transparent",
        }}
      >
        <DrawerBody
          sx={{
            background: "#97E181",
            borderRadius: "20px 20px 0 0",
            padding: 0,
          }}
        >
          <Flex
            sx={{
              padding: "0 22px",
              height: "48px",
              paddingTop: "6px",
            }}
            align={"center"}
            justify={"space-between"}
          >
            <Text
              sx={{
                color: "#000",
                fontSize: "15px",
                fontWeight: 500,
              }}
            >
              {" "}
              {title}
            </Text>

            <Image
              onClick={onClose}
              src={CloseIcon}
              sx={{
                width: "20px",
                cursor: "pointer",
              }}
            ></Image>
          </Flex>

          <Box
            sx={{
              background: "#232523",
              padding: "22px",
              borderRadius: "20px 20px 0 0",
            }}
          >
            {children}
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default PlayDrawerWrap;
