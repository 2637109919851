import { Box, Flex } from "@chakra-ui/react";
import React from "react";

const Nav = ({
  active,
  onChangeActive,
}: {
  active: number;
  onChangeActive: (active: number) => void;
}) => {
  return (
    <Flex
      justify={"space-between"}
      sx={{
        width: "100%",
        padding: "0 30px",
        fontSize: "15px",
        marginTop: "30px",
        "& *": {
          transition: "all 0.3s",
        },
      }}
      onClick={() => {}}
    >
      <Flex
        align={"center"}
        justify={"center"}
        sx={{
          background: active === 0 ? "#97E181" : "#232523",
          color: active === 0 ? "#000" : "#A1A1A1",
          border: active == 0 ? "1px solid #97E181" : "1px solid #A1A1A1",
          width: "46%",
          height: "41px",
          cursor: "pointer",
          borderRadius: "16px",
        }}
        onClick={() => {
          onChangeActive(0);
        }}
      >
        PEPES
      </Flex>
      <Flex
        align={"center"}
        justify={"center"}
        sx={{
          background: active === 1 ? "#97E181" : "#232523",
          color: active === 1 ? "#000" : "#A1A1A1",
          border: active == 1 ? "1px solid #97E181" : "1px solid #A1A1A1",

          width: "46%",
          height: "41px",
          cursor: "pointer",
          borderRadius: "16px",
        }}
        onClick={() => {
          onChangeActive(1);
        }}
      >
        Partners
      </Flex>
    </Flex>
  );
};

export default Nav;
