import { Box, Text, Image, Flex, Button } from "@chakra-ui/react";
import { GreenButton } from "../Button";
import TonPepeIcon from "./assets/ton-pool.png";
import USDTPepeIcon from "./assets/usdt-pool.png";
import DogsPepeIcon from "./assets/dogs-pool.png";
import USDTIcon from "./assets/usdt.png";
import config from "@/constants/tgConfig";
import TonIcon from "./assets/ton-icon.png";

const poolList = [
  {
    isNative: true,
    name: "TON",
    jettonMaster: "",
    img: TonPepeIcon,
    icon: TonIcon,
    decimals: 9,
  },
  {
    isNative: false,
    name: "USDT",
    jettonMaster: config.usdt,
    img: USDTPepeIcon,
    icon: USDTIcon,
    decimals: 6,
  },
  {
    isNative: true,
    name: "DOGS",
    jettonMaster: "",
    img: DogsPepeIcon,
    icon: TonIcon,
    decimals: 9,
  },
];

const Pool = ({ onShowDetail }: { onShowDetail: (pool: any) => void }) => {
  return (
    <Box
      sx={{
        padding: "20px 20px",
      }}
    >
      <Text
        sx={{
          fontSize: "30px",
          fontStyle: "normal",
          fontWeight: 900,
          textAlign: "center",
          color: "#FFF",
        }}
      >
        GET MORE PEPES!
      </Text>
      <Flex
        flexDir={"column"}
        align={"center"}
        sx={{
          width: "100%",
          gap: "20px",
          marginTop: "20px",
        }}
      >
        {poolList.map((item, index) => {
          return (
            <Box
              onClick={() => {
                onShowDetail(item);
              }}
              key={index}
              sx={{
                border: "3px solid #000",
                padding: "12px 12px 12px 20px",
                width: "100%",
                background: "#232523",
                borderRadius: "20px",
              }}
            >
              <Flex align={"center"}>
                <Flex
                  sx={{
                    width: "78px",
                    height: "78px",
                    marginRight: "26px",
                    position: "relative",
                  }}
                  align={"center"}
                  justify={"center"}
                >
                  <Image
                    src={item.img}
                    sx={{
                      width: "78px",
                      height: "78px",
                    }}
                  ></Image>
                  <Image
                    src={TonIcon}
                    sx={{
                      width: "45px",
                      position: "absolute",
                      bottom: "-10px",
                      right: "-10px",
                    }}
                  ></Image>
                </Flex>
                <Box
                  sx={{
                    flex: 1,
                  }}
                >
                  <Text
                    sx={{
                      fontSize: "20px",
                      fontWeight: 900,
                      color: "#fff",
                    }}
                  >
                    {item.name} POOL
                  </Text>
                  <Text
                    sx={{
                      marginTop: "14px",
                      fontSize: "15px",
                      color: "#7C7C7C",
                    }}
                  >
                    Stake {item.name} Get $PEPES
                  </Text>
                  <GreenButton
                    sx={{
                      marginTop: "14px",
                      borderRadius: "10px",
                      height: "31px !important",
                      fontWeight: 900,
                    }}
                  >
                    LIVE
                  </GreenButton>
                </Box>
              </Flex>
            </Box>
          );
        })}
      </Flex>
    </Box>
  );
};
export default Pool;
