import { Box, Flex, Image, keyframes, Text } from "@chakra-ui/react";
import { useMemo } from "react";
import FiIcon from "@/assets/fi.svg";
import ErrorIcon from "@/assets/error.svg";
import LoadingIcon from "@/assets/loading.svg";
import { useUserData } from "@/contexts/SubmitRequest";

const rotateKeyframe = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const YellowButton = ({
  text,
  status,
  onClick,
}: {
  text: string;
  status: number;
  onClick?: () => void;
  background?: string;
}) => {
  const icon = useMemo(() => {
    if (status == 1) {
      return FiIcon;
    } else if (status == 2) {
      return ErrorIcon;
    } else if (status == 3) {
      return LoadingIcon;
    }
    return "";
  }, [status]);

  return (
    <Flex
      onClick={onClick}
      justify={"center"}
      align={"center"}
      sx={{
        borderRadius: "10px",
        height: "33px",
        background: status == 1 ? "#97E181" : status == 2 ? "#CE0034" : "#fff",
        padding: "0px 6px",
        width: "72px",
        cursor: "pointer",
        color: "#000",
      }}
    >
      {icon ? (
        <Image
          src={icon}
          sx={{
            width: "20px",
            height: "20px",
          }}
          animation={`${status == 3 && rotateKeyframe} 2s linear infinite`}
        ></Image>
      ) : (
        text
      )}
    </Flex>
  );
};

export const LoadingText = ({ width = "111px" }: { width?: string }) => {
  return (
    <Box
      sx={{
        width: width,
        height: "11px",
        borderRadius: "26px",
        background: "#F0F0F0",
      }}
    ></Box>
  );
};
interface List {
  btnText: string;
  title: string;
  amount: number;
  url: string;
  changeType: number;
  des?: string;
  type: number;
  icon?: string;
  symbol?: string;
}

interface TaskInfo {
  bg?: string;
  bgColor?: string;
  title: string;
  logo: string;
  list: List[];
}

const TaskWrap = ({
  info,
  currentTask,
  currentStatus,
  completeList,
  handleDoTask,
}: {
  info: TaskInfo;
  currentTask: number; //当前任务
  currentStatus: number; //当前任务状态
  completeList: any; //所有已完成任务列表
  handleDoTask: (scoreType: number) => Promise<void>;
}) => {
  const { connectWalletCount } = useUserData();
  // const newItem = { ...item };

  // if (connectWalletCount <= 5000) {
  //   newItem.amount = 0.01;
  //   newItem.symbol = "TON";
  // }
  const list = info.list;
  return (
    <Box
      id={"potus"}
      sx={{
        width: "100%",
        fontWeight: " bold",
      }}
    >
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Flex
          sx={{
            paddingTop: "20px",
          }}
          align={"center"}
          justify={"space-between"}
        >
          <Text>{info.title}</Text>
        </Flex>
        <Flex
          sx={{
            color: "#FFF",
            borderRadius: "16px",
            background: info.bgColor ? info.bgColor : `url(${info.bg})`,
            backgroundSize: "cover",
            padding: "16px 10px",
            gap: "24px",
            marginTop: "10px",
          }}
          flexDir={"column"}
        >
          {list.map((item, index) => {
            const newItem = { ...item };
            let status = 0;

            if (connectWalletCount <= 5000 && newItem.changeType == 240) {
              newItem.amount = 0.01;
              newItem.symbol = "TON";
            }

            const fItem = completeList.find(
              (i: any) => i.changeType == item.changeType
            );

            if (fItem) {
              status = 1;
            } else if (currentTask == item.changeType) {
              status = currentStatus;
            }

            return (
              <Box key={index}>
                <Box>
                  <Flex justify={"space-between"}>
                    <Flex align={"center"}>
                      <Image
                        src={newItem.icon ? newItem.icon : info.logo}
                        sx={{
                          width: "35px",
                          height: "35px",
                          marginRight: "12px",
                        }}
                      ></Image>
                      <Box>
                        <Text
                          sx={{
                            fontSize: "15px",
                            fontWeight: "500",
                          }}
                        >
                          {newItem.title}
                        </Text>
                        <Text
                          sx={{
                            textAlign: "left",
                            fontSize: "15px",
                            marginTop: "4px",
                            color: status == 1 ? "#97E181" : "#A1A1A1",
                            fontWeight: 500,
                          }}
                        >
                          +{newItem.amount}{" "}
                          {newItem.symbol ? newItem.symbol : "PEPES"}
                        </Text>
                      </Box>
                    </Flex>
                    <Box
                      sx={{
                        position: "relative",
                        color: "#fff",
                      }}
                    >
                      <YellowButton
                        text={status == 0 ? newItem.btnText : ""}
                        status={status}
                        onClick={async () => {
                          if (status == 1) {
                            if (newItem.type == 1) {
                              window.Telegram.WebApp.openLink(newItem.url);
                              return;
                            }
                            window.Telegram.WebApp.openTelegramLink(
                              newItem.url
                            );
                            return;
                          }
                          if (newItem.type == 1) {
                            window.Telegram.WebApp.openLink(newItem.url);
                          } else if (newItem.type == 0) {
                            window.Telegram.WebApp.openTelegramLink(
                              newItem.url
                            );
                          }
                          await handleDoTask(newItem.changeType);
                        }}
                      ></YellowButton>
                    </Box>
                  </Flex>
                </Box>
              </Box>
            );
          })}
        </Flex>
      </Box>
    </Box>
  );
};

export default TaskWrap;
