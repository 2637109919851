import { Box, Flex, Text, Image, useDisclosure } from "@chakra-ui/react";
import TonIcon from "./assets/tonicon.png";
import { ReactComponent as PRRIcon } from "./assets/prr.svg";
import { useMemo } from "react";
import { GameInfo } from "@/pages/Play";
import { useUserData } from "@/contexts/SubmitRequest";
import { accAdd, accDiv, accMul, toFixed } from "@/utils/formatBalance";
import Countdown from "react-countdown";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import TimeIcon from "./assets/time.png";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import PEPELogoJson from "@/components/Play/assets/pepe-logo.json";
import Lottie from "lottie-react";

dayjs.extend(utc);
dayjs.extend(timezone);

const ConnectWallet = () => {
  const [tonConnectUI, setOptions] = useTonConnectUI();

  const handleLogin = () => {
    tonConnectUI.openModal();
  };

  return (
    <Flex
      onClick={handleLogin}
      align={"center"}
      justify={"center"}
      sx={{
        borderRadius: "10px",
        border: "1px solid #fff",
        background: "#fff",
        height: "48px",
        marginTop: "12px",
        fontSize: "15px",
        fontWeight: 900,
        color: "#000",
      }}
    >
      Connect
    </Flex>
  );
};

const LoadingBt = () => {
  return (
    <Flex
      align={"center"}
      justify={"center"}
      sx={{
        borderRadius: "10px",
        border: "1px solid #97E181",
        background: "transparent",
        height: "48px",
        marginTop: "12px",
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: 900,
        color: "#A1A1A1",
        cursor: "not-allowed",
      }}
    >
      <Lottie
        style={{
          width: "34px",
          marginRight: "10px",
        }}
        animationData={PEPELogoJson}
        loop={true}
      />
      <Text
        sx={{
          color: "#A1A1A1",
          fontSize: "15px",
          fontWeight: 900,
        }}
      >
        wait to load
      </Text>
    </Flex>
  );
};

const Entered = ({ children }: { children?: React.ReactNode }) => {
  return (
    <Flex
      align={"center"}
      justify={"center"}
      sx={{
        borderRadius: "10px",
        border: "1px solid #000",
        background: "#4C4C4C",
        height: "48px",
        marginTop: "12px",
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: 900,
        color: "#A1A1A1",
        cursor: "not-allowed",
      }}
    >
      {children}
    </Flex>
  );
};

const EnterNow = ({
  onClick,
  children,
}: {
  onClick?: () => void;
  children?: React.ReactNode;
}) => {
  return (
    <Flex
      onClick={onClick}
      align={"center"}
      justify={"center"}
      sx={{
        borderRadius: "10px",
        border: "1px solid #4C4C4C",
        background: "#97E181",
        height: "48px",
        marginTop: "12px",
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: 900,
        color: "#000",
      }}
    >
      {children}
    </Flex>
  );
};

const GrayClose = ({ children }: { children?: React.ReactNode }) => {
  return (
    <Flex
      align={"center"}
      justify={"center"}
      sx={{
        borderRadius: "10px",
        border: "1px solid #4C4C4C",
        background: "#4C4C4C",
        height: "48px",
        marginTop: "12px",
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: 900,
        color: "#A1A1A1",
      }}
    >
      {children}
    </Flex>
  );
};

const BackToCurrent = ({ onClick }: { onClick: () => void }) => {
  return (
    <Flex
      onClick={onClick}
      align={"center"}
      justify={"center"}
      sx={{
        borderRadius: "10px",
        border: "1px solid #97E181",
        background: "#283228",
        height: "48px",
        marginTop: "12px",
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: 900,
        color: "#97E181",
      }}
    >
      <Text
        sx={{
          marginRight: "8px",
        }}
      >
        Back to Current Round
      </Text>
      <PRRIcon
        style={{
          color: "#97E181",
        }}
      ></PRRIcon>
    </Flex>
  );
};

const renderer = ({
  formatted,
}: {
  formatted: {
    hours: string;
    minutes: string;
    seconds: string;
  };
}) => {
  return (
    <Flex
      align={"center"}
      sx={{
        borderRadius: "10px",
        border: "1px solid #97E181",
        height: "30px",
        width: "88px",
        fontSize: "23px",
        color: "#97E181",
        fontWeight: "bold",
      }}
      justify={"center"}
    >
      {formatted.minutes}:{formatted.seconds}
    </Flex>
  );
};
const InButton = ({
  loading,
  currentRound,
  lastRound,
  betted,
  selectAmountIndex,
  onClick,
  status,
  onBackToCurrent,
}: {
  loading: boolean;
  currentRound: number;
  lastRound: number;
  betted: boolean;
  selectAmountIndex: number;
  onClick: () => void;
  onBackToCurrent: () => void;
  status: number;
}) => {
  const rawAddress = useTonAddress();

  if (!rawAddress) {
    return <ConnectWallet></ConnectWallet>;
  }

  if (loading) {
    return <LoadingBt></LoadingBt>;
  }

  if (Number(currentRound) < Number(lastRound)) {
    return <BackToCurrent onClick={onBackToCurrent}></BackToCurrent>;
  }

  if (status == 2 || status == 3 || status == 4 || status == 5) {
    return <GrayClose>Round Close</GrayClose>;
  }

  if (betted) {
    return <Entered>Entered</Entered>;
  }

  if (selectAmountIndex == -1) {
    return <GrayClose>Please select the amount </GrayClose>;
  }

  return <EnterNow onClick={onClick}>Enter now</EnterNow>;
};

const OutButton = ({
  loading,
  onBackToCurrent,
  currentRound,
  lastRound,
  onClick,
  betted,
  status,
}: {
  loading: boolean;
  betted: boolean;
  onBackToCurrent: () => void;
  currentRound: number;
  lastRound: number;
  onClick: () => void;
  status: number;
}) => {
  const rawAddress = useTonAddress();
  if (!rawAddress) {
    return <ConnectWallet></ConnectWallet>;
  }

  if (loading) {
    return <LoadingBt></LoadingBt>;
  }

  if (Number(currentRound) < Number(lastRound)) {
    return <BackToCurrent onClick={onBackToCurrent}></BackToCurrent>;
  }

  if (status == 2 || status == 3 || status == 4 || status == 5) {
    return <GrayClose>Round Close</GrayClose>;
  }

  if (betted) {
    return <Entered>Entered</Entered>;
  }

  return <EnterNow onClick={onClick}>Enter now</EnterNow>;
};

const RoundEnter = ({
  loading,
  currentRound,
  lastRound,
  onBet,
  selectAmountIndex,
  gameInfo,
  showBet,
  onShowBet,
  onToRound,
}: {
  loading: boolean;
  currentRound: number;
  lastRound: number;
  selectAmountIndex: number;
  gameInfo: GameInfo;
  showBet: boolean;
  onShowBet: () => void;
  onBet: (count: number) => void;
  onToRound: (id: number, zhuan: boolean) => void;
}) => {
  const { userData } = useUserData();
  const [yourEnter, yourWin, betted] = useMemo(() => {
    const yourBetItem = gameInfo.betHistorys.find((item) => {
      return item.userId === userData.userId;
    });
    if (yourBetItem) {
      const allBet = gameInfo.betHistorys.reduce((acc, item) => {
        return accAdd(acc, item.betAmount);
      }, "0");

      const rate = accMul(accDiv(yourBetItem.betAmount, allBet), 100);

      return [yourBetItem.betAmount, rate, true];
    }

    return ["0", "0", false];
  }, [gameInfo, userData]);

  const countdown = useMemo(() => {
    return dayjs.utc(gameInfo.createTime).local().add(90, "second").valueOf();
  }, [gameInfo.createTime]);

  const handleEnterNow = () => {
    if (!showBet) {
      onShowBet();
      return;
    }
  };

  return (
    <Box
      sx={{
        background: "#283228",
        padding: "8px 20px",
        position: "absolute",
        bottom: "65px",
        left: "0",
        width: "100%",
        height: "158px",
      }}
    >
      <Flex
        align={"center"}
        justify={"space-between"}
        sx={{
          height: "30px",
        }}
      >
        <Text>Round #{gameInfo.id}</Text>

        {gameInfo.createTime != "0" && gameInfo.status == 1 ? (
          <Countdown key={countdown} date={countdown} renderer={renderer} />
        ) : (
          <Image
            src={TimeIcon}
            sx={{
              width: "70px",
            }}
          ></Image>
        )}
      </Flex>
      <Flex
        sx={{
          marginTop: "14px",
        }}
      >
        <Box
          sx={{
            flex: 1,
          }}
        >
          <Text
            sx={{
              fontSize: "13px",
              color: "#A1A1A1",
            }}
          >
            Prize Pool
          </Text>
          <Flex
            align={"center"}
            sx={{
              marginTop: "4px",
            }}
          >
            <Image
              src={TonIcon}
              sx={{
                width: "18px",
                marginRight: "4px",
              }}
            ></Image>
            <Text
              sx={{
                fontSize: "15px",
              }}
            >
              {gameInfo.totalBetAmount}
            </Text>
          </Flex>
        </Box>
        <Box
          sx={{
            flex: 1,
          }}
        >
          <Text
            sx={{
              fontSize: "13px",
              color: "#A1A1A1",
            }}
          >
            Your Entries
          </Text>
          <Flex
            align={"center"}
            sx={{
              marginTop: "4px",
            }}
          >
            <Image
              src={TonIcon}
              sx={{
                width: "18px",
                marginRight: "4px",
              }}
            ></Image>
            <Text
              sx={{
                fontSize: "15px",
              }}
            >
              {yourEnter}
            </Text>
          </Flex>
        </Box>
        <Box
          sx={{
            flex: 1,
          }}
        >
          <Text
            sx={{
              fontSize: "13px",
              color: "#A1A1A1",
            }}
          >
            Your Win Chance
          </Text>
          <Flex
            align={"center"}
            sx={{
              marginTop: "4px",
            }}
          >
            <Image
              src={TonIcon}
              sx={{
                width: "18px",
                marginRight: "4px",
              }}
            ></Image>
            <Text
              sx={{
                fontSize: "15px",
              }}
            >
              {toFixed(yourWin, 2)}%
            </Text>
          </Flex>
        </Box>
      </Flex>
      {showBet ? (
        <InButton
          loading={loading}
          currentRound={currentRound}
          lastRound={lastRound}
          betted={betted}
          onBackToCurrent={() => {
            onToRound(lastRound, true);
          }}
          selectAmountIndex={selectAmountIndex}
          onClick={() => {
            onBet(1);
          }}
          status={gameInfo.status}
        ></InButton>
      ) : (
        <OutButton
          loading={loading}
          onBackToCurrent={() => {
            onToRound(lastRound, true);
          }}
          betted={betted}
          currentRound={currentRound}
          lastRound={lastRound}
          onClick={handleEnterNow}
          status={gameInfo.status}
        ></OutButton>
      )}
    </Box>
  );
};

export default RoundEnter;
